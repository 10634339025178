import { ACTIONS } from '../constants/actions';

const initialState = {
  getClaimedRewardData: null,
  promoList: null,
  promoFilter: [],
};

export default function promotionReducer(promotion = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.PROMOTION.BONUS:
      return { ...promotion, promoList: payload };
    case ACTIONS.PROMOTION.BONUS_FILTER:
      return { ...promotion, promoFilter: payload };
    default:
      return promotion;
  }
}
