import { ACTIONS } from '../constants/actions';

/**
 * Banner List Action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setBannerList = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.BANNER.BANNER_LIST,
  });

export const setShortcutList = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.BANNER.SHORTCUT_LIST,
  });
