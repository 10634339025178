import axios from 'axios';
import { API } from 'common/constants/api';

const { HOST, APIBASE, PUBLIC } = API;

const PublicApi = {
  getCheckMaintenance: async (params) => {
    const response = await axios.get(`${HOST}${APIBASE}${PUBLIC.CHECK_MAINTENANCE}`);
    return response;
  },
  getThirdPartyScriptInfo: async (memberReferralCode) => {
    const response = await axios.get(
      `${HOST}${APIBASE}${PUBLIC.GET_METAPIXEL_INFO.replace(':code', memberReferralCode)}`,
    );
    return response;
  },
  logDownloadAppIp: async (memberReferralCode) => {
    const response = await axios.get(
      `${HOST}${APIBASE}${PUBLIC.LOG_DOWNLOADAPPIP.replace(':code', memberReferralCode)}`,
    );
    return response;
  },
  updateMetaEvt: async () => {
    const response = await axios.post(`${HOST}${APIBASE}${PUBLIC.UPDATE_META_EVT}`);
    return response;
  },
  getBrandSupportUrl: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${PUBLIC.GET_BRAND}`);
    return response;
  },
  getBaseSetting: async (baseSettingKey) => {
    const response = await axios.get(`${HOST}${APIBASE}${PUBLIC.GETBASESETTINGBYKEY.replace('{baseSettingKey}',baseSettingKey).replace('{webCode}',window.WEB_CODE)}`);
    return response;
  },
  getExternalLinks: async (pageName) => {
    const response = await axios.get(`${HOST}${APIBASE}${PUBLIC.GET_BY_PAGE.replace(':page', pageName)}`);
    return response;
  },
};

export default PublicApi;
