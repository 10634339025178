import axios from 'axios';
import { API } from 'common/constants/api';

const { HOST, APIBASE, INVITE_REFERRAL_SPIN } = API;

const ReferralSpinApi = {
  getReferralSpinDetails: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.PAGE_DETAIL}`);
    return response;
  },
  spinReferralBonus: async () => {
    const response = await axios.post(`${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.SPIN}`);
    return response;
  },
  claimReferralBonus: async () => {
    const response = await axios.post(`${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.CLAIM}`);
    return response;
  },
  getLuckyUserList: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.LUCKY_USER_LIST}`);
    return response;
  },
  getRegisterRecordList: async () => {
    const response = await axios.get(
      `${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.REGISTER_RECORD_LIST}`, {
        standardResponse: true,
      },
    );
    return response;
  },
};

export default ReferralSpinApi;
