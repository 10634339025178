import { ACTIONS } from '../constants/actions';

const initialState = {
  showDailyLoginBonusPopUp: false,
  showDailyLoginBonusFloatButton: false,
  showReferralBonusPopUp: false,
  showReferralBonusFloatButton: false,
  showFirstSpinModalOpen: false,
  showDailyWeeklyBonusPopUp: false,
  showDailyWeeklyBonusFloatButton: false,
  showDepositAppBonusPopUp: false,
  showDepositAppBonusFloatButton: false,
  currentPopUpOrder: null,
  activePopUpList: [],
  showWelcomeBonusPopUp: false,
  showMemberDepositBonusPopUp: false,
  showSysAnnouncementPopUp: false,
  sysAnnouncementList: [],
};

export default function popupReducer(popup = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.DAILYLOGINBONUS.POPUP:
      return { ...popup, showDailyLoginBonusPopUp: payload };
    case ACTIONS.DAILYLOGINBONUS.FLOATBUTTON:
      return { ...popup, showDailyLoginBonusFloatButton: payload };
    case ACTIONS.REFERRAL.POPUP:
      return { ...popup, showReferralBonusPopUp: payload };
    case ACTIONS.REFERRAL.FLOATBUTTON:
      return { ...popup, showReferralBonusFloatButton: payload };
    case ACTIONS.REFERRAL.FIRSTSPINMODALOPEN:
      return { ...popup, showFirstSpinModalOpen: payload };
    case ACTIONS.DAILYWEEKLYBONUS.POPUP:
      return { ...popup, showDailyWeeklyBonusPopUp: payload };
    case ACTIONS.DAILYWEEKLYBONUS.FLOATBUTTON:
      return { ...popup, showDailyWeeklyBonusFloatButton: payload };
    case ACTIONS.POPUP.DEPOSITAPPBONUS_POPUP:
      return { ...popup, showDepositAppBonusPopUp: payload };
    case ACTIONS.POPUP.DEPOSITAPPBONUS_FLOATBUTTON:
      return { ...popup, showDepositAppBonusFloatButton: payload };
      case ACTIONS.HOME.CURRENTPOPUPORDER:
        return { ...popup, currentPopUpOrder: payload };
      case ACTIONS.HOME.ACTIVEPOPUPLIST:
        return { ...popup, activePopUpList: payload };
      case ACTIONS.POPUP.POPUP_LIST:
        return { ...popup, popUpList: payload };
      case ACTIONS.HOME.WELCOMEPOPUP:
        return { ...popup, showWelcomeBonusPopUp: payload };
      case ACTIONS.HOME.MEMBERDEPOSITPOPUP:
        return { ...popup, showMemberDepositBonusPopUp: payload };
      case ACTIONS.POPUP.SYSANNOUNCEMENTPOPUP:
        return { ...popup, showSysAnnouncementPopUp: payload };
      case ACTIONS.POPUP.SYSANNOUNCEMENTLIST:
        return { ...popup, sysAnnouncementList: payload };
    default:
      return popup;
  }
}
