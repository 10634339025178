import { useEffect, useState } from 'react';
import { useColorScheme } from '@mui/material/styles';
import { API } from 'common/constants/api';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const themeUrl = `${API.CDN_REACT}/mobile/theme/${window.THEME_CODE}_`;

const CustomIcon = ({ src, className, alt, type = null, isLazyLoad = false, ...props }) => {
  const { mode } = useColorScheme();
  const [themeIconSrc, setThemeIconSrc] = useState(null);

  useEffect(() => {
    const icon = src.substring(src.lastIndexOf('/') + 1);
    const iconUrl = `${themeUrl}${mode}${type ? `/${type}/` : '/'}${icon}`;
    setThemeIconSrc(iconUrl);
  }, [src, mode, type]);

  return (
    <>
      {themeIconSrc && !isLazyLoad ? (
        <img className={className} src={themeIconSrc} alt={alt} {...props} />
      ) : null}
      {themeIconSrc && isLazyLoad ? (
        <LazyLoadImage className={className} src={themeIconSrc} alt={alt} {...props} />
      ) : null}
    </>
  );
};

export default CustomIcon;
