import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ssGetUID } from 'common/helpers/sessionStorage';
import { useGetBalance } from 'services/query/walletQuery';
import { enqueueSnackbarMsg } from 'common/helpers/helper';

export function useSignalR() {
  let connection = null;
  const reconnectDelays = [0, 2000, 5000];
  const [, refetch] = useGetBalance();

  const startConnection = async (memberId) => {
    try {
      const uid = ssGetUID();
      if (uid) {
        connection = new HubConnectionBuilder()
          .withUrl(`${window.SIGNALR_NOTI_HUB_URL}?memberId=${memberId}&user=${uid}`) // Connect to the hub
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
              return retryContext.previousRetryCount < reconnectDelays.length
                ? reconnectDelays[retryContext.previousRetryCount]
                : 10000;
            },
          })
          .configureLogging(LogLevel.None)
          .build();

        await connection.start(); // Start the connection
        console.log('SignalR Connected!');

        connection.on('UpdateBalance', (result) => {
          refetch();
          enqueueSnackbarMsg(result.message, result.isSuccess ? 'success' : 'error');
        });
      }
    } catch (error) {
      // console.error('Error while establishing SignalR connection:', error);
    }
  };

  const stopConnection = async () => {
    try {
      if (connection) {
        await connection.stop(); // Stop the connection
        console.log('SignalR Disconnected!');
      }
    } catch (error) {
      // console.error('Error while stopping SignalR connection:', error);
    }
  };

  // // Start the connection when the component using the hook mounts
  // useEffect(() => {
  //   startConnection();

  //   // Clean up the SignalR connection when the component unmounts
  //   return () => {
  //     stopConnection();
  //   };
  // }, []);

  // Return the functions that can be used in the component using the hook
  return {
    startConnection,
    stopConnection,
  };
}
