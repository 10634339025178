import axios from 'axios';
import { API } from 'common/constants/api';

const { HOST, APIBASE, ACCOUNT } = API;

const AccountApi = {
  userPing: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${ACCOUNT.PING}`);
    return response;
  },
  getUserSessionActive: async (userId) => {
    const response = await axios.get(`${HOST}${APIBASE}${ACCOUNT.SESSION_ACTIVE}/${userId}`);
    return response;
  },
  getCurrentUser: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${ACCOUNT.CURRENT_USER}`, data);
    return response;
  },
  resetPassword: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${ACCOUNT.RESET_PASSWORD}`, data);
    return response;
  },
  updateAccount: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${ACCOUNT.UPDATE}`, data);
    return response;
  },
  verifyAccount: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${ACCOUNT.VERIFY}`, data);
    return response;
  },
  requestVerifyAccount: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${ACCOUNT.REQUEST_VERIFY}`, data);
    return response;
  },
  validateResetCode: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${ACCOUNT.VALIDATE_RESET_CODE}`, data);
    return response;
  },
  verifyPassword: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${ACCOUNT.VERIFY_PASSWORD}`, data);
    return response;
  },
  removeMemberPhoneorEmail: async (isEmail) => {
    const response = await axios.post(
      `${HOST}${APIBASE}${ACCOUNT.REMOVE_MEMBER_PHONE_OR_EMAIL}?isEmail=${isEmail}`,
    );
    return response;
  },
  requestOTP: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${ACCOUNT.REQUEST_VERIFY_EMAIL_OTP}`, data);
    return response;
  },
};

export default AccountApi;
