import { ACTIONS } from '../constants/actions';

export const setReferralSpinDetails = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.REFERRAL.REFERRAL_SPIN_DETAILS,
  });

export const setSpinAwardAmount = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.REFERRAL.AWARD_SPIN_AMOUNT,
  });

  export const setShowWinningAmount = (dispatch, payload) =>
    dispatch({
      payload,
      type: ACTIONS.REFERRAL.SHOW_WINNING_AMOUNT,
    });
  
  export const setReferralBonusWonAmount = (dispatch, payload) =>
    dispatch({
      payload,
      type: ACTIONS.REFERRAL.REFERRAL_BONUS_WON_AMOUNT,
    });