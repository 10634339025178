const IconIOS = ({ isActive, color }) => {
  return (
    <svg width="22" height="24" viewBox="0 0 22 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.527 0.00508203C15.4531 -0.00620969 15.3693 0.000847635 15.2783 0.0309589C14.0659 0.271849 12.7337 0.99452 11.885 1.83764C11.1575 2.56031 10.5518 3.76476 10.5518 4.84876C10.5518 5.08965 10.7924 5.33101 11.0349 5.33101C12.3685 5.21057 13.7027 4.48743 14.5514 3.64431C15.2788 2.68075 15.8845 1.59722 15.8845 0.51321C15.8845 0.242208 15.7486 0.0389572 15.527 0.00508203ZM15.8845 5.21104C13.7022 5.21104 12.7337 6.53546 11.2788 6.53546C9.70264 6.53546 8.24774 5.33101 6.42911 5.33101C3.88303 5.33101 0.00234558 7.73945 0.00234558 13.2799C-0.118896 18.3386 4.48877 24 7.15609 24C8.73223 24 9.09643 23.0355 11.2788 23.0355C13.4611 23.0355 13.9461 24 15.5222 24C17.3409 24 18.6745 22.0724 19.7657 20.5066C20.2507 19.6635 20.6144 19.1827 20.9781 18.46C21.2206 17.9782 20.9777 17.4964 20.4927 17.2555C17.3404 15.6898 16.7351 10.6311 20.2511 8.82439C20.8574 8.46306 20.9777 7.73897 20.4927 7.37764C19.159 6.17319 17.2182 5.21104 15.8845 5.21104Z"
        fill={color}
      />
    </svg>
  );
};

export default IconIOS;
