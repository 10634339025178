import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import axios from 'axios';
import { GAMECATEGORY_TYPE, STORAGE } from '../constants/keys';
import { getLocalStorage, setLocalStorage } from './localStorage';
import { Settings } from 'common/constants/common';
import CryptoJS from 'crypto-js';
import { enqueueSnackbar } from 'notistack';

export function isToday(date) {
  const compareDate = new Date(date);
  const today = new Date();
  return (
    compareDate.getDate() === today.getDate() &&
    compareDate.getMonth() === today.getMonth() &&
    compareDate.getFullYear() === today.getFullYear()
  );
}

export function isEmptyString(value) {
  return !value || value === '';
}

export function isString(value) {
  return _.isString(value) && !_.isEmpty(value);
}

export function isArray(value) {
  return _.isArray(value) && !_.isEmpty(value);
}

export function isNumber(value) {
  return _.isNumber(value) && _.isFinite(value) && !_.isNaN(value);
}

export function isDate(value) {
  return _.isDate(value);
}

export function isEmail(value) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

export function isInPhone(value) {
  try {
    const reg = /^1[3-9]\d{9}$/;
    return reg.test(value);
  } catch (error) {
    return false;
  }
}

export const isValidInputName = (input) => {
  const regex = /^[a-zA-Z0-9]{6,20}$/i;
  return regex.test(input);
};

export const isValidRegInputNameMin = (input) => {
  const regex = /^.{6,20}$/i;
  return regex.test(input);
};

export const isValidRegInputNameCharc = (input) => {
  const regex = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/i;
  return regex.test(input);
};

export const isValidRegSpecialCharc = (input) => {
  const regex = /^[A-Za-z0-9]+$/i;
  return regex.test(input);
};

export const isValidNickName = (input) => {
  const regex = /^[a-zA-Z0-9]{1,20}$/i;
  return regex.test(input);
};

export const isValidPassword = (input) => {
  const regex = /^.{6,}/i;
  // console.log(input, regex.test(input));
  return regex.test(input);
};

export const isValidNumber = (input) => {
  const regex = /^[0-9\b]+$/;
  return regex.test(input);
};

export const isValidAccountName = (input) => {
  const regex = /^[[a-zA-Z\b]+$/;
  return regex.test(input);
};

export const isValidIFSC = (input) => {
  const regex = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
  return regex.test(input);
};
export const isValidUSDT = (input) => {
  const regex = /^T[A-Za-z0-9]{33}$/;
  return regex.test(input);
};

export const encryptUSDTAddress = (text) => {
  if (!text) {
    return '';
  }
  if (text.length >= 30) {
    const lenUnencrypt = (text.length - 20) / 2;
    return (
      text.substring(0, lenUnencrypt) +
      '********************' +
      text.substring(lenUnencrypt + 20, text.length)
    );
  } else {
    return text.replace(/./g, '*');
  }
};

export function paramstoLowercase(params) {
  const lowerParams = {};
  for (const key in params) {
    lowerParams[key.toLowerCase()] = params[key];
  }

  return lowerParams;
}

export const getParamFromUrl = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export function isFormatAmt(value) {
  return Math.abs(value) > 999
    ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) + 'k'
    : Math.sign(value) * Math.abs(value);
}

export const objectToUrlParam = (obj) => {
  const params = Object.entries(obj)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
};

//https://stackoverflow.com/questions/4460586/javascript-regular-expression-to-check-for-ip-addresses
//https://stackoverflow.com/questions/23483855/javascript-regex-to-validate-ipv4-and-ipv6-address-no-hostnames
export const ValidateIPaddress = (ipaddress) => {
  let ipv4_valid =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress,
    );
  let ipv6_valid =
    /^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm.test(
      ipaddress,
    );
  if (ipv4_valid || ipv6_valid) {
    return true;
  }

  return false;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

//https://getjsonip.com/#plus
//https://www.cloudflare.com/cdn-cgi/trace
//https://1.1.1.1/cdn-cgi/trace
export const getClientIpByList = async () => {
  let retry = false;
  let index = 0;
  let list = [
    'https://www.cloudflare.com/cdn-cgi/trace',
    'https://api.ipify.org/?format=json',
    'https://jsonip.com/',
    'http://www.geoplugin.net/json.gp',
    'https://geolocation-db.com/json/',
    // 'http://no-tls.jsonip.com/',
  ];
  // shuffleArray(list);

  let response = [undefined, undefined];
  do {
    let requestUrl = list[index];
    retry = false;
    const instance = axios.create({
      withCredentials: false,
    });
    response = await instance
      .get(requestUrl, { withCredentials: false, timeout: 2000 })
      .then((data) => {
        switch (requestUrl) {
          case 'https://www.cloudflare.com/cdn-cgi/trace':
            let arr = data.data
              .trim()
              .split('\n')
              .map((e) => e.split('='));
            let obj = Object.fromEntries(arr);
            return [obj.ip, undefined];
          case 'https://api.ipify.org/?format=json':
            return [data?.data?.ip || undefined, undefined];
          case 'https://geolocation-db.com/json/':
            return [data?.data?.IPv4 || undefined, undefined];
          case 'http://www.geoplugin.net/json.gp':
            return [data?.data?.geoplugin_request || undefined, undefined];
          case 'http://no-tls.jsonip.com/':
            return [data?.data?.ip || undefined, undefined];
          case 'https://jsonip.com/':
            return [data?.data?.ip || undefined, undefined];
          default:
            return [undefined, 'unknow api'];
        }
      })
      .catch((error) => {
        return Promise.resolve([undefined, error]);
      });

    // console.log(response);
    // console.log(ValidateIPaddress('2a01:7e00::f03c:93ff:fe33:72cd'));
    // console.log(ValidateIPaddress('2606:4700:4700::64'));
    // console.log(ValidateIPaddress('::1111:2222:3333:4444:5555:6666::'));
    // console.log(ValidateIPaddress('1:2:3::4:5::7:8'));
    if (response[0] === undefined || response[1] !== undefined || !ValidateIPaddress(response[0])) {
      retry = true;
    }

    if (index >= list.length - 1) {
      retry = false;
      response = [null, 'Ip Address error'];
    } else {
      index = index + 1;
    }
  } while (retry);

  return response;
};

export const getClientIp = async () => {
  return await axios.get('https://api.ipify.org/?format=json', { withCredentials: false });
};

export const handlePromise = (promise) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]));
};

export const sortListAsc = (list) => {
  return list.sort((a, b) => (b.order != null) - (a.order != null) || a.order - b.order);
};

export const sortListDesc = (list) => {
  return list.sort((a, b) => (b.order != null) - (a.order != null) || b.order - a.order);
};

export const sortbydisable = (list) => {
  return list.sort((a, b) => Number(a.disabledBonus) - Number(b.disabledBonus));
};

export const sortPayTypeListAsc = (payTypelist) => {
  return payTypelist.sort(
    (a, b) =>
      (b.payTypeOrder != null) - (a.payTypeOrder != null) || a.payTypeOrder - b.payTypeOrder,
  );
};

export const sortMaintenance = (list) => {
  return list.sort(
    (a, b) =>
      (b.isMaintenance === false) - (a.isMaintenance === false) ||
      a.isMaintenance - b.isMaintenance,
  );
};

export const isValidTheme = (theme) => {
  const themeList = ['default', 'vip'];
  let isValidTheme = themeList.includes(theme);
  return isValidTheme;
};

export const loadTheme = (isMobile) => {
  let theme = 'default';
  let platform = 'desktop';
  let storageTheme = getLocalStorage(STORAGE.Theme);
  if (!isValidTheme(storageTheme)) {
    storageTheme = 'default';
  }

  if (storageTheme !== undefined) {
    theme = storageTheme;
  }

  removeCss(theme);
  const cssExtension = (window.MINIFY_CSS ?? false) ? '.min.css' : '.css';
  if (isMobile) {
    platform = 'mobile';
  }

  let themePath = '/css/' + platform + '/' + theme + cssExtension;
  setLocalStorage(STORAGE.Theme, theme);
  loadCss(themePath, theme);
};

export const switchTheme = (theme) => {
  if (theme === undefined || theme === '' || !isValidTheme(theme)) {
    theme = 'default';
  }

  let platform = 'desktop';
  if (isMobile) {
    platform = 'mobile';
  }
  const cssExtension = (window.MINIFY_CSS ?? false) ? '.min.css' : '.css';
  let themePath = '/css/' + platform + '/' + theme + cssExtension;
  let storageTheme = getLocalStorage(STORAGE.Theme);

  if (storageTheme === undefined) {
    loadCss(themePath, theme);
  } else if (storageTheme !== undefined && storageTheme !== theme) {
    removeCss(storageTheme);
    loadCss(themePath, theme);
  }
  setLocalStorage(STORAGE.Theme, theme);
};

export const loadCss = (url, id) => {
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  link.id = id;
  document.head.appendChild(link);
};

export const removeCss = (id) => {
  var el = document.getElementById(id);
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};

//prevent axios unhandle error: bad request or other http error
//returned error msg still shown by legacy method
export const silenceError = (promise) => promise.catch(() => {});

export const onImgError = (e) => {
  e.target.src = Settings.SLOT_DEFAULT_IMG;
};

export const getUniqueListByValue = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const getGameCatType = (name) => {
  let result = GAMECATEGORY_TYPE[name];
  const gameList = getLocalStorage('gameCategoryList');

  if (gameList) {
    const catList = gameList.data.subgameCategoryType;
    const gameCat = catList.filter((i) => i.name.toLowerCase() === name.toLowerCase());
    if (gameCat.length > 0) {
      result = gameCat[0].subgameCategoryId;
    }
  }
  return result;
};

// export const getSportCatType = (name) => {
//   let result = SPORTCATEGORY_TYPE[name];
//   return result;
// };

export const isIOS = () => {
  const userAgent = window.navigator.userAgent;
  const iosDevice = /iPhone|iPad|iPod|Mac/i.test(userAgent);
  return iosDevice;
};
export const downloadAPKClick = () => {
  const downloadLink = document.createElement('a');
  downloadLink.href = window.DOWNLOAD_URL;
  downloadLink.download = 'Sungame-installer.apk';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  if (downloadLink.parentNode) {
    downloadLink.parentNode.removeChild(downloadLink);
  }
};

export const toggleNativeOrientation = (value) => {
  const params = {
    toggleOrientation: true,
    gameUrl: value,
  };
  const fromUniApp = getLocalStorage(STORAGE.FromUniApp);
  if (typeof window.FROM_INR91RN !== 'undefined') {
    window.ReactNativeWebView?.postMessage(JSON.stringify(params));
  } else if (fromUniApp) {
    window.location.href = `uniwebview://action_enableRotateScreenLandscape?isEnable=${value}`;
    if (!value) {
      window.location.href = 'uniwebview://action_rotateScreen?orientation=Portrait';
    }
  } else {
    window.jsBridge?.postMessage('toggleOrientation', JSON.stringify(params));
  }
};

export const replaceSpaceWithHyphens = (title) => title.replace(/\s+/g, '-').toLowerCase();
export const filterNumbers = (phoneNumber) =>
  phoneNumber && phoneNumber.startsWith('+86-') ? phoneNumber.slice(4) : phoneNumber;

export const hashInBase64 = (userId, secretkey) => {
  var hash = CryptoJS.HmacSHA256(userId, secretkey);
  return CryptoJS.enc.Hex.stringify(hash);
};

/**
 * Handle enqueueSnackbar Message
 */
export const enqueueSnackbarMsg = (msg, variant = 'success', title = null) => {
  return enqueueSnackbar(msg, {
    title: title,
    variant: variant,
    autoHideDuration: 2500,
  });
};

export const handleError = (error) => {
  // Default error handling logic
  let errorMsg = error;
  if (error.message) {
    errorMsg = error.message;
  } else if (error.errorMessage) {
    errorMsg = error.errorMessage;
  }
  enqueueSnackbarMsg(errorMsg, 'error');
};

export const getPormoByType = (list, type) => {
  return list.filter((i) => i.displayType === type);
};

export function formatResult(mutation, isQuery = false) {
  return [
    {
      data: mutation.data,
      loading: isQuery ? mutation.isLoading : mutation.isPending,
      error: mutation.isError ? mutation.error : null,
    },
    isQuery ? mutation.refetch : mutation.mutate,
  ];
}

export const getIndiaTimeNow = () => {
  const date = new Date();
  const localTime = date.getTime();
  const localOffset = date.getTimezoneOffset() * 60000;
  const utc = localTime + localOffset;
  const offset = 5.5; // UTC of India Time Zone is +05.30
  const india = utc + 3600000 * offset;
  return new Date(india);
};