const IconHome = ({ color }) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23441 10.3788C3.07352 10.759 3.04286 10.9533 3.04286 11V19.0018C3.04286 19.5529 3.49895 20 4.06429 20H7.12857V14C7.12857 12.8954 8.04319 12 9.17143 12H13.2571C14.3854 12 15.3 12.8954 15.3 14V20H18.3643C18.9296 20 19.3857 19.5529 19.3857 19.0018V11C19.3857 10.9533 19.3551 10.759 19.1942 10.3788C19.0448 10.0256 18.8181 9.5926 18.5223 9.1025C17.9316 8.1238 17.1028 6.97999 16.1752 5.89018C15.2454 4.79788 14.2417 3.78969 13.3084 3.06454C12.8419 2.70204 12.413 2.42608 12.037 2.2444C11.6525 2.05862 11.3819 2 11.2143 2C11.0467 2 10.7761 2.05862 10.3915 2.2444C10.0155 2.42608 9.58664 2.70204 9.12013 3.06454C8.18686 3.78969 7.1832 4.79788 6.25343 5.89018C5.3258 6.97999 4.49697 8.1238 3.90627 9.1025C3.61044 9.5926 3.38386 10.0256 3.23441 10.3788ZM9.48746 0.45091C10.0246 0.19138 10.6158 0 11.2143 0C11.8127 0 12.4039 0.19138 12.9411 0.45091C13.4868 0.71454 14.0395 1.07921 14.5784 1.49796C15.6561 2.33531 16.7591 3.45212 17.7445 4.60982C18.7321 5.77001 19.6269 7.0012 20.2811 8.08502C20.6077 8.62614 20.8838 9.1462 21.0815 9.6134C21.2677 10.0535 21.4286 10.5467 21.4286 11V19.0018C21.4286 20.6599 20.0555 22 18.3643 22H15.3C14.1717 22 13.2571 21.1046 13.2571 20V14H9.17143V20C9.17143 21.1046 8.25681 22 7.12857 22H4.06429C2.37315 22 1 20.6599 1 19.0018V11C1 10.5467 1.16085 10.0535 1.3471 9.6134C1.54477 9.1462 1.82091 8.62614 2.14752 8.08502C2.80169 7.0012 3.69652 5.77001 4.68407 4.60982C5.66948 3.45212 6.77251 2.33531 7.85018 1.49796C8.38912 1.07921 8.94182 0.71454 9.48746 0.45091Z"
        fill={color}
      />
    </svg>
  );
};

export default IconHome;
