import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import styles from './SubmitButton.module.css';
import { fontSize, fontWeight } from '@mui/system';

const useStyle = makeStyles((theme) => ({
  root: {
    '&.MuiButton-containedPrimary': {
      color: `${theme.palette.text.secondary} `,
      background: `${theme.palette.bg.action1}`,
      textTransform: 'none',
      fontWeight: 600,
    },
    '&.MuiButton-containedPrimary:hover': {
      background: `${theme.palette.bg.action1}`,
      filter: 'brightness(50%)',
    },
    '&.MuiButton-outlinedPrimary': {
      background: 'rgba(44, 74, 121, 1)',
      borderColor: `${theme.palette.bg.action1}`,
      textTransform: 'none',
    },
    '&.MuiButton-outlinedPrimary:hover': {
      filter: 'brightness(50%)',
    },
    '&.Mui-disabled': {
      background: '#939BB1 !important',
      color: '#575B67',
    },
  },
}));

const SubmitButton = ({
  value,
  externalClassName = '',
  type = 'submit',
  handleClick = null,
  btnDisabled = false,
  children,
  childrenWrapperStyle,
  isPrimary = true,
  ...rest
}) => {
  const classes = useStyle();
  return (
    <Button
      disabled={btnDisabled}
      variant="contained"
      type={type}
      color={isPrimary ? 'primary' : 'inherit'}
      onClick={type === 'submit' ? null : handleClick}
      {...rest}
      className={`${styles.button} ${classes.root} ${externalClassName}`}
    >
      <div style={childrenWrapperStyle}>{children}</div>
      {value}
    </Button>
  );
};

export default memo(SubmitButton);
