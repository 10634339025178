import { sortPayTypeListAsc } from 'common/helpers/helper';
import { ACTIONS } from '../constants/actions';

const initialState = {
  getClaimedRewardData: null,
  getVIPData: {
    currVipLevel: -1,
    currVipName: '',
    userTurnover: 0,
    nextLevel: -1,
    nextLevelName: '',
    nextTurnoverRequired: null,
    bonusList: null,
  },
  bonusListResult: [],
  userVIPInfo: {
    currVipLevel: -1,
    currVipName: '',
    userBalance: 0,
    depositExceeded7Days: 0,
  },
  vipLevelBonusList: [],
  vipCurrIndex: 0,
};

export default function bonusReducer(bonus = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.BONUS.GETCLAIMABLEREWARD:
      return { ...bonus, getClaimedRewardData: payload.data };
    case ACTIONS.BONUS.GETVIPDATA:
      return { ...bonus, getVIPData: payload.data };
    case ACTIONS.BONUS.GETBONUSLIST:
      return { ...bonus, bonusListResult: payload.data };
    case ACTIONS.BONUS.USERVIPINFO:
      return { ...bonus, userVIPInfo: payload.vipInfo };
    case ACTIONS.BONUS.VIPLVLBONUSLIST:
      return { ...bonus, vipLevelBonusList: payload.vipLvlBonus };
    case ACTIONS.BONUS.VIPCURRINDEX:
      return { ...bonus, vipCurrIndex: payload.vipCurrIndex };
    default:
      return bonus;
  }
}
