const IconSlots = ({ isActive, color }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9201 19.2468H1V5.98002C1 3.79101 2.79101 2 4.98002 2H12.9401C15.1291 2 16.9201 3.79101 16.9201 5.98002V19.2468Z"
          stroke="white"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.6133 7.30673H6.30664V13.9401H11.6133V7.30673Z"
          stroke="white"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.3067 7.30673H1V13.9401H6.3067V7.30673Z"
          stroke="white"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.9202 7.30673H11.6135V13.9401H16.9202V7.30673Z"
          stroke="white"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.30664 16.5934H10.95"
          stroke="white"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.5735 4.65335C20.3062 4.65335 20.9002 4.05938 20.9002 3.32667C20.9002 2.59397 20.3062 2 19.5735 2C18.8408 2 18.2468 2.59397 18.2468 3.32667C18.2468 4.05938 18.8408 4.65335 19.5735 4.65335Z"
          stroke="white"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.9202 12.6134H19.5735V4.65332"
          stroke="white"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.98975 9.29669H4.31642L3.91842 9.69469C3.32141 10.2917 2.98975 11.0877 2.98975 11.95"
          stroke="white"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.29663 9.29669H9.62331L9.2253 9.69469C8.6283 10.2917 8.29663 11.0877 8.29663 11.95"
          stroke="white"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.6035 9.29669H14.9302L14.5322 9.69469C13.9352 10.2917 13.6035 11.0877 13.6035 11.95"
          stroke="white"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default IconSlots;
