const IconHistory = ({ color = '#939BB1' }) => {
  return (
    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5001_3610)">
        <path d="M2.98615 11.3317L7.76149 8.52538L0 6.63574L2.98615 11.3317Z" fill={color} />
        <path
          d="M7.58651 14.4692C8.75507 15.3257 10.2007 15.8395 11.7703 15.8395C15.6292 15.8395 18.7685 12.7712 18.7685 8.99932C18.7685 5.22817 15.6292 2.15911 11.7703 2.15911C7.91138 2.15911 4.77215 5.22749 4.77215 8.99932H2.5625C2.5625 4.03724 6.69296 0 11.7703 0C16.8477 0 20.9781 4.03724 20.9781 9C20.9781 13.9628 16.8477 18 11.7703 18C8.79663 18 6.14989 16.6141 4.46529 14.4699H7.58651V14.4692Z"
          fill={color}
        />
        <path
          d="M12.1621 11.1444C11.4694 11.1444 10.9062 10.594 10.9062 9.91694C10.9062 9.23989 11.4694 8.68945 12.1621 8.68945C12.8548 8.68945 13.4179 9.23989 13.4179 9.91694C13.4179 10.594 12.8548 11.1444 12.1621 11.1444Z"
          fill={color}
        />
        <path
          d="M12.8079 9.30798L11.5195 9.31746L11.7398 5.3723L12.5433 5.36621L12.8079 9.30798Z"
          fill={color}
        />
        <path
          d="M11.6735 9.28711L11.6652 10.5457L8.68945 10.3325L8.69499 9.50106L11.6735 9.28711Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5001_3610">
          <rect width="21" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconHistory;
