const IconBonus = ({ isActive, color }) => {
  return (
    <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11876_25386)">
      <g clipPath="url(#clip1_11876_25386)">
        <path d="M19 11H3V21H19V11Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
        <path
          d="M20.5454 8H1.45465C1.20355 8 1 8.18923 1 8.42265V10.5773C1 10.8108 1.20355 11 1.45465 11H20.5454C20.7964 11 21 10.8108 21 10.5773V8.42265C21 8.18923 20.7964 8 20.5454 8Z"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
        />
        <path
          d="M16.2922 1.75697C15.9002 1.34922 15.4112 1.0896 14.8898 1.01241C12.7763 0.747161 12.0611 4.79104 11.0143 7.68086C10.9998 7.72314 10.9962 7.76947 11.0041 7.81408C11.0119 7.8587 11.0309 7.89965 11.0585 7.93185C11.0862 7.96406 11.1213 7.9861 11.1596 7.99525C11.1979 8.00441 11.2377 8.00026 11.274 7.98334C13.7392 6.76412 17.0513 5.90323 16.9994 3.47411C16.9851 3.14758 16.9153 2.82762 16.7938 2.53283C16.6724 2.23804 16.5019 1.97431 16.2922 1.75697Z"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
        />
        <path
          d="M7.11017 1.01238C6.58978 1.08876 6.10186 1.34855 5.71178 1.75694C5.50169 1.97421 5.33065 2.23784 5.20858 2.53258C5.08651 2.82732 5.01582 3.14733 5.0006 3.47409C4.94866 5.90322 8.26084 6.76412 10.726 7.98334C10.7623 8.00026 10.8021 8.00441 10.8404 7.99525C10.8787 7.9861 10.9138 7.96406 10.9415 7.93185C10.9691 7.89965 10.9881 7.8587 10.9959 7.81408C11.0038 7.76946 11.0002 7.72314 10.9857 7.68086C9.9389 4.80034 9.22373 0.747133 7.11017 1.01238Z"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
        />
        <path d="M11 8V21" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11876_25386">
        <rect width="22" height="22" fill={color} />
      </clipPath>
      <clipPath id="clip1_11876_25386">
        <rect width="22" height="22" fill={color} />
      </clipPath>
    </defs>
  </svg>
  );
};

export default IconBonus;
