import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store/StateProvider';
import VaultPromptPassword from './VaultPromptPassword';
import CustomTab from 'component/shared/tabs/CustomTab';
import { DEPOSIT_WITHDRAW_TAB } from 'common/constants/tabs';
import { useSubmitVault } from 'services/query/walletQuery';
import VaultTransfer from './VaultTransfer';
import { useLoading } from 'component/shared/provider/LoadingProvider';
import styles from './Vault.module.css';
import VaultHistory from './VaultHistory';

const initFormData = {
  amount: 0,
  password: '',
};
const Vault = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [store] = useStore();
  const { isLoading } = useLoading();
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(true);
  const [bindingTab, setBindingTab] = useState('deposit');
  const [formData, setFormData] = useState({
    ...initFormData,
  });
  // const [,] = useGetBalance();
   const balanceData = store.wallet?.balanceData;
  const [{ data: dataSubmit, loading: loadingSubmit }, submitVault] = useSubmitVault();

  useEffect(() => {
    if (dataSubmit) {
      if (dataSubmit.result === 1) {
        setFormData(initFormData);
      }
    }
  }, [dataSubmit]);

  useEffect(() => {
    isLoading('vaultTransfer', loadingSubmit);
  }, [loadingSubmit, isLoading]);

  const handleBindingTabChange = useCallback((e, newTab) => {
    if (newTab) {
      setBindingTab(newTab);
      setFormData(initFormData);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    const data =
      bindingTab === 'deposit'
        ? {
          amount: parseFloat(formData.amount),
          type: 0,
        }
        : {
          amount: parseFloat(formData.amount),
          password: formData.password,
          type: 1,
        };
    submitVault(data);
  }, [formData]);

  const handleChange = useCallback(
    (prop) => (e) => {
      setFormData((d) => ({
        ...d,
        [prop]: e.target.value,
      }));
    },
    [],
  );
  const setAll = (deposit = true) => {
    setFormData((d) => ({
      ...d,
      amount: deposit ? balanceData.truncatedBalance : balanceData.truncatedVaultBalance,
    }));
  };
  return (
    <>
      <VaultPromptPassword
        navigate={navigate}
        showPasswordPrompt={showPasswordPrompt}
        setShowPasswordPrompt={setShowPasswordPrompt}
        intl={intl}
      />

      {!showPasswordPrompt && (
        <>
          <div className={styles.tabContainer}>
            <div className={styles.customTab}>
              <CustomTab
                tab={DEPOSIT_WITHDRAW_TAB}
                tabValue={bindingTab}
                handleTabChange={handleBindingTabChange}
              />
            </div>
          </div>

          {bindingTab && bindingTab !== 'history' && (
            <VaultTransfer
              balanceData={balanceData}
              intl={intl}
              handleSubmit={handleSubmit}
              formData={formData}
              handleChange={handleChange}
              setAll={setAll}
              bindingTab={bindingTab}
            />
          )}
          {bindingTab && bindingTab === 'history' && <VaultHistory intl={intl} />}
        </>
      )}
    </>
  );
};

export default Vault;