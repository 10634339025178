import { ACTIONS } from '../constants/actions';


const initialState = {
  referralSpinDetails: [],
  award_spin_amount:'0.00',
  showWinningAmount: false,
  referralBonusWonAmount: 0,
};
export default function referralReducer(referral = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.REFERRAL.REFERRAL_SPIN_DETAILS:
      return {
        ...referral,
        referralSpinDetails: payload,
      };
      case ACTIONS.REFERRAL.AWARD_SPIN_AMOUNT:
        return {
          ...referral,
          award_spin_amount: payload,
        };
      case ACTIONS.REFERRAL.SHOW_WINNING_AMOUNT:
        return {
          ...referral,
          showWinningAmount: payload,
        };
        case ACTIONS.REFERRAL.REFERRAL_BONUS_WON_AMOUNT:
          return {
            ...referral,
            referralBonusWonAmount: payload,
          };
    default:
      return referral;
  }
}