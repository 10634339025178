import axios from 'axios';
import { API } from 'common/constants/api';

const { HOST, APIBASE, GAME } = API;

const GameApi = {
  fetchGetMainPageCategoryGame: async (params) => {
    const response = await axios.get(`${HOST}${APIBASE}${GAME.GET_MAIN_PAGE_CATEGORY_GAME}`);
    return response;
  },
  launchGame: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${GAME.LAUNCH_GAME}`, data);
    return response;
  },
  launchDemoGame: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${GAME.LAUNCH_DEMO_GAME}`, data);
    return response;
  },
  getSubGameList: async (subGameType = null) => {
    let param = '';
    if (subGameType) {
      param += `/${subGameType}`;
    }
    const response = await axios.get(`${HOST}${APIBASE}${GAME.SUBGAME}${param}`);
    return response;
  },
  getRecentGamePlayed: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${GAME.GET_RECENT_GAME_PLAY}`);
    return response;
  },
  getGameCacheVersion: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${GAME.GAME_CACHE_VERSION}`);
    return response;
  },
  getFavGameList: async () => {
    const response = await axios.post(`${HOST}${APIBASE}${GAME.GET_FAV_GAMELIST}`);
    return response;
  },
  getFavGameIdList: async () => {
    const response = await axios.post(`${HOST}${APIBASE}${GAME.GET_FAV_GAMEIDLIST}`);
    return response;
  },
  toggleFavGameList: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${GAME.TOGGLE_FAV_GAMELIST}`, data);
    return response;
  },
  getGameCategory: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${GAME.GET_GAME_CATEGORY}`);
    return response;
  },
  getWinList: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${GAME.GET_WIN_LIST}`);
    return response;
  },
};

export default GameApi;
