const IconReferral = ({ color }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8417 17.3813C21.6031 15.6845 20.9119 13.6161 19.2232 12.8514C19.4085 9.04029 17.281 5.73011 14.168 4.34906C14.246 2.4142 12.7782 0.85 11.0001 0.85C9.22214 0.85 7.75438 2.41552 7.83225 4.34904C4.69482 5.73992 2.59097 9.06959 2.7759 12.8533C1.09323 13.6158 0.394724 15.682 1.15861 17.3814C1.91822 19.0717 3.8726 19.8193 5.50407 18.908C8.64073 21.896 13.357 21.8987 16.4963 18.908C18.1263 19.8179 20.0807 19.0746 20.8417 17.3814L20.8417 17.3813ZM8.04184 5.42765C8.502 6.67452 9.64885 7.56948 10.9975 7.56948C12.3459 7.56948 13.4924 6.67501 13.9529 5.42747C14.752 5.81198 15.4834 6.35141 16.1058 7.01565L10.9975 12.4373L5.88924 7.01556C6.51045 6.35155 7.24263 5.81217 8.04184 5.42765ZM10.4799 13.4281V20.0502C8.94492 19.9351 7.50264 19.3079 6.32983 18.2447C7.20091 17.2703 7.4728 15.7936 6.89012 14.4956L6.89011 14.4956C6.31764 13.2207 5.07755 12.4876 3.79026 12.5894C3.75111 10.8901 4.25356 9.21659 5.21762 7.84287L10.4799 13.4281ZM3.10588 13.8801L3.10601 13.8801C4.16649 13.343 5.44208 13.8155 5.95425 14.956L5.95427 14.9561C6.46829 16.0994 6.00879 17.4596 4.94276 17.9995C3.88359 18.5353 2.60538 18.06 2.09578 16.9237L2.0636 16.8519C1.59974 15.7238 2.06221 14.4074 3.10588 13.8801ZM15.105 14.4955C14.5222 15.7924 14.793 17.2692 15.6653 18.2461C14.4926 19.3091 13.0492 19.9352 11.5151 20.0515V13.4281L16.7774 7.84296C17.7393 9.21559 18.2439 10.8892 18.2048 12.5896C16.9429 12.4909 15.6889 13.1979 15.105 14.4955L15.105 14.4955ZM17.055 17.9982L17.0549 17.9981C15.9878 17.4583 15.5293 16.0982 16.0434 14.9533L16.0434 14.9532C16.5532 13.8179 17.8263 13.3391 18.8903 13.8787L18.8904 13.8787C19.9564 14.4186 20.416 15.7787 19.902 16.9236C19.3911 18.06 18.1129 18.5339 17.055 17.9982ZM10.9988 1.92892C12.1709 1.92892 13.1391 2.94348 13.1391 4.20836C13.1391 5.47335 12.1708 6.48918 10.9988 6.48918C9.82687 6.48918 8.85856 5.47467 8.85856 4.20836C8.85856 2.94215 9.82676 1.92892 10.9988 1.92892Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default IconReferral;
