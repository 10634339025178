const IconAndroid = ({ color }) => {
  return (
    <svg width="22" height="24" viewBox="0 0 22 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.24 9.2483V15.4542C3.24 16.2795 2.55938 16.9387 1.69313 16.9387C0.8325 16.9387 0 16.2741 0 15.4542V9.2483C0 8.43907 0.8325 7.77454 1.69313 7.77454C2.5425 7.77454 3.24 8.43907 3.24 9.2483ZM3.8475 17.6621C3.8475 18.541 4.59 19.2485 5.5125 19.2485H6.63188L6.64875 22.5229C6.64875 24.5004 9.75375 24.4843 9.75375 22.5229V19.2485H11.8463V22.5229C11.8463 24.4897 14.9681 24.495 14.9681 22.5229V19.2485H16.1044C17.0156 19.2485 17.7581 18.541 17.7581 17.6621V8.04786H3.8475V17.6621ZM17.7975 7.52802H3.78563C3.78563 5.23431 5.22563 3.24071 7.36312 2.20104L6.28875 0.309265C6.13125 0.0466669 6.53063 -0.119466 6.66563 0.105617L7.75688 2.01347C9.72 1.1828 11.9756 1.22568 13.8488 2.01347L14.9344 0.110977C15.075 -0.119466 15.4688 0.052026 15.3113 0.314624L14.2369 2.20104C16.3575 3.24071 17.7975 5.23431 17.7975 7.52802ZM8.19563 4.5537C8.19563 4.24823 7.93688 3.99099 7.605 3.99099C7.28438 3.99099 7.03125 4.24823 7.03125 4.5537C7.03125 4.85917 7.29 5.11641 7.605 5.11641C7.93688 5.11641 8.19563 4.85917 8.19563 4.5537ZM14.5744 4.5537C14.5744 4.24823 14.3156 3.99099 14.0006 3.99099C13.6687 3.99099 13.41 4.24823 13.41 4.5537C13.41 4.85917 13.6687 5.11641 14.0006 5.11641C14.3156 5.11641 14.5744 4.85917 14.5744 4.5537ZM19.9069 7.77454C19.0575 7.77454 18.36 8.423 18.36 9.2483V15.4542C18.36 16.2795 19.0575 16.9387 19.9069 16.9387C20.7731 16.9387 21.6 16.2741 21.6 15.4542V9.2483C21.6 8.423 20.7675 7.77454 19.9069 7.77454Z"
        fill={color}
      />
    </svg>
  );
};

export default IconAndroid;
