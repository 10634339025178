import { useEffect, useRef } from 'react';

import { useGetThirdPartyScriptInfo } from 'services/query/publicQuery';

import { loadPixelScript } from 'common/helpers/metaPixel';
import useFetchReferCode from './useFetchReferCode';
import { setLocalStorage } from 'common/helpers/localStorage';

const useLoadThirdPartyScript = () => {
  const [, memberReferralCode] = useFetchReferCode();
  const firstRender = useRef(true);

  const [{ data }, getInfo] = useGetThirdPartyScriptInfo(memberReferralCode);

  useEffect(() => {
    if (firstRender.current && window.METAPIXEL_ID !== '') {
      if (typeof memberReferralCode === 'undefined') {
        loadPixelScript(window.METAPIXEL_ID);
        setLocalStorage('PIXELID', window.METAPIXEL_ID);
        setLocalStorage('MemberReferralCode_API', '');
        firstRender.current = false;
      } else if (memberReferralCode !== '') {
        getInfo();
        firstRender.current = false;
      }
    }
  }, [memberReferralCode, getInfo]);

  useEffect(() => {
    if (data) {
      let pixelId = window.METAPIXEL_ID;
      if (data.result === 1) {
        pixelId = data.pixelId;
      }
      loadPixelScript(pixelId);
      setLocalStorage('PIXELID', pixelId);
      setLocalStorage(
        'MemberReferralCode_API',
        data.inviteCode !== memberReferralCode ? data.inviteCode : '',
      );
    }
  }, [data, memberReferralCode]);

  return [];
};

export default useLoadThirdPartyScript;
