import { ACTIONS } from '../constants/actions';

const initialState = {
  spinChances: 0,
  users: null,
  balance: 0,
  memberBalance: null,
  userInfo: null,
  openVerifyPopup: false,
  removePhoneorEmail: false,
  activeSMSPlatform: false,
};

export default function userReducer(user = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.USER.USER_INFO:
      return { ...user, userInfo: payload };
    case ACTIONS.USER.USER_DETAILS_SET:
      return { ...user, users: payload };
    case ACTIONS.USER.USER_BALANCE:
      return { ...user, memberBalance: payload };
    case ACTIONS.USER.USER_LOGOUT:
      return undefined;
    case ACTIONS.USER.SPIN_CHANCE:
      return { ...user, spinChances: payload };
    case ACTIONS.USER.VERIFY_POPUP:
      return { ...user, openVerifyPopup: payload };
    case ACTIONS.USER.REMOVE_PHONE:
      return { ...user, removePhoneorEmail: payload };
    case ACTIONS.USER.ACTIVE_SMS_PLATFORM:
      return { ...user, activeSMSPlatform: payload };
    default:
      return user;
  }
}
