import { STORAGE } from 'common/constants/keys';

//set the same key order in popup banner (BO)
export const StorageKeys = {
  ReferralWheelSpinPopUp: STORAGE.HideReferralBonusPopUp,
  DailyLoginBonusPopUp: STORAGE.HideDailyLoginBonusPopUp,
  DepositLoginAppBonusPopUp: STORAGE.HideDepositAppBonusPopUp,
  DailyWeeklyBonusPopUp: STORAGE.HideDailyWeeklyBonusPopUp,
  MemberDepositBonusPopUp: STORAGE.HideMemberDepositBonusPopUp,
  DoubleWinBonusPopUp: STORAGE.HideDoubleWinBonusPopUp,
  WelcomeBonusPopUp: STORAGE.HideWelcomeBonusPopUp,
};