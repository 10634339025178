import axios from 'axios';
import { API } from 'common/constants/api';

const { HOST, APIBASE, BANNER, POPUPBANNER, EVENTURL, SHORTCUT } = API;

const BannerApi = {
  fetchBannerList: async (type) => {
    const response = await axios.get(`${HOST}${APIBASE}${BANNER.GET.replace(':type', type)}`);
    return response;
  },
  fetchPopupBanner: async (type) => {
    const response = await axios.get(`${HOST}${APIBASE}${POPUPBANNER.GET.replace(':type', type)}`);
    return response;
  },
  getBannerListVersion: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${BANNER.BANNER_CACHE_VERSION}`);
    return response;
  },
  getPopupBannerListVersion: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${POPUPBANNER.POPUP_BANNER_CACHE_VERSION}`);
    return response;
  },
  getEventUrl: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${EVENTURL.GET}`);
    return response;
  },
  fetchShortcutList: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${SHORTCUT.GET_SHORTCUT}`);
    return response;
  },
  getShortcutListVersion: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${SHORTCUT.SHORTCUT_CACHE_VERSION}`);
    return response;
  },
};

export default BannerApi;
