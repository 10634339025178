const IconSupport = ({ color }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7087 7.4175C19.4856 7.4175 19.2701 7.45009 19.0645 7.51025C18.2623 3.79512 14.9508 1 11 1C7.04922 1 3.73519 3.79512 2.93551 7.51025C2.72994 7.45009 2.51436 7.4175 2.29125 7.4175C1.0278 7.4175 0 8.4453 0 9.70875V12.4587C0 13.7222 1.0278 14.75 2.29125 14.75C3.55469 14.75 4.5825 13.7222 4.5825 12.4587V9.25C4.5825 5.71035 7.46034 2.8325 11 2.8325C14.5396 2.8325 17.4175 5.71035 17.4175 9.25V12C17.4175 14.3088 16.1917 16.397 14.2313 17.5401C13.8152 16.961 13.1358 16.585 12.3712 16.585H9.62124C8.35779 16.585 7.32999 17.6128 7.32999 18.8763C7.32999 20.1397 8.35779 21.1675 9.62124 21.1675H12.3712C13.4467 21.1675 14.3516 20.4205 14.5973 19.4202C16.6429 18.4275 18.152 16.6702 18.8389 14.5795C19.1071 14.6898 19.4004 14.75 19.7087 14.75C20.9722 14.75 22 13.7222 22 12.4587V9.70875C22.0025 8.4453 20.9722 7.4175 19.7087 7.4175ZM2.75 12.4613C2.75 12.7144 2.54444 12.92 2.29125 12.92C2.03806 12.92 1.8325 12.7144 1.8325 12.4613V9.70875C1.8325 9.45556 2.03806 9.25 2.29125 9.25C2.54444 9.25 2.75 9.45556 2.75 9.70875V12.4613ZM12.3712 19.3375H9.62124C9.36805 19.3375 9.16249 19.1319 9.16249 18.8788C9.16249 18.6256 9.36805 18.42 9.62124 18.42H12.3712C12.6244 18.42 12.83 18.6256 12.83 18.8788C12.83 19.1319 12.6244 19.3375 12.3712 19.3375ZM20.1675 12.4613C20.1675 12.7144 19.9619 12.92 19.7087 12.92C19.4556 12.92 19.25 12.7144 19.25 12.4613V9.71126C19.25 9.45807 19.4556 9.25251 19.7087 9.25251C19.9619 9.25251 20.1675 9.45807 20.1675 9.71126V12.4613Z"
        fill={color}
      />
      <path
        d="M10.9997 14.7525C13.0227 14.7525 14.6672 13.108 14.6672 11.085H12.8347C12.8347 12.0952 12.0124 12.9175 11.0022 12.9175C9.99191 12.9175 9.16967 12.0952 9.16967 11.085H7.33717C7.33216 13.108 8.97664 14.7525 10.9997 14.7525Z"
        fill={color}
      />
    </svg>
  );
};

export default IconSupport;
