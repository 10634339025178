import { ACTIONS } from '../constants/actions';

/**
 * Snack Bar action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setAffiliateSnackBar = (dispatch, payload = true) =>
  dispatch({
    payload,
    type: ACTIONS.COMMON.AFFILIATESNACKBAR,
  });

/**
 * Welcome Snack Bar action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setWelcomeSnackBar = (dispatch, payload = true) =>
  dispatch({
    payload,
    type: ACTIONS.COMMON.WELCOMESNACKBAR,
  });

/**
 *SideBar Drawer Open action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setSideBarDrawerOpen = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COMMON.SIDEBARDRAWEROPEN,
  });

export const setTheme = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COMMON.THEME,
  });

export const setCommon = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COMMON.COMMON_SET,
  });

export const clearCommon = (dispatch) =>
  dispatch({
    type: ACTIONS.COMMON.COMMON_CLEAR,
  });
