const IconMoney = ({ width = 32, height = 32 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14739_6744)">
        <path
          d="M27.4778 25.9181V17.7396C27.4778 12.6855 24.1941 8.3841 19.6481 6.85629C20.6154 5.90873 21.2172 4.58929 21.2172 3.13141V1.04454C21.2172 0.270539 20.4009 -0.235461 19.7071 0.111289C17.386 1.27204 14.6145 1.27204 12.2929 0.111289C11.5982 -0.236086 10.7828 0.271977 10.7828 1.04454V3.13141C10.7828 4.58929 11.3846 5.90873 12.3519 6.85629C7.80593 8.3841 4.52218 12.6854 4.52218 17.7396V25.9181C3.30755 26.3487 2.4353 27.5092 2.4353 28.8697C2.43524 30.5957 3.83955 32 5.56562 32H26.4344C28.1604 32 29.5647 30.5957 29.5647 28.8697C29.5647 27.5092 28.6924 26.3487 27.4778 25.9181ZM12.8697 2.5881C14.9017 3.22729 17.0983 3.22729 19.1303 2.5881V3.13148C19.1303 4.85754 17.7261 6.26179 16 6.26179C14.2739 6.26179 12.8697 4.85754 12.8697 3.13148V2.5881ZM26.4344 29.9131H5.56562C4.99024 29.9131 4.52218 29.445 4.52218 28.8697C4.52218 28.2943 4.99024 27.8262 5.56562 27.8262C6.14187 27.8262 6.60905 27.359 6.60905 26.7828V17.7397C6.60905 12.5615 10.8219 8.34873 16 8.34873C21.1781 8.34873 25.3909 12.5615 25.3909 17.7397V26.7828C25.3909 27.359 25.8581 27.8262 26.4344 27.8262C27.0097 27.8262 27.4778 28.2943 27.4778 28.8697C27.4778 29.445 27.0097 29.9131 26.4344 29.9131Z"
          fill="white"
        />
        <path
          d="M16.4455 18.2084C15.3508 17.6298 14.2188 17.0314 14.2188 16.3484C14.2188 15.3894 14.999 14.6093 15.9579 14.6093C16.9168 14.6093 17.697 15.3894 17.697 16.3484C17.697 16.9246 18.1641 17.3918 18.7404 17.3918C19.3166 17.3918 19.7838 16.9246 19.7838 16.3484C19.7838 14.6005 18.6052 13.1237 17.0013 12.6682V12.1746C17.0013 11.5984 16.5341 11.1312 15.9579 11.1312C15.3816 11.1312 14.9145 11.5984 14.9145 12.1746V12.6682C13.3106 13.1237 12.132 14.6005 12.132 16.3484C12.132 18.2888 13.983 19.2672 15.4703 20.0534C16.565 20.632 17.697 21.2304 17.697 21.9134C17.697 22.8723 16.9168 23.6524 15.9579 23.6524C14.999 23.6524 14.2188 22.8723 14.2188 21.9134C14.2188 21.3371 13.7516 20.8699 13.1754 20.8699C12.5991 20.8699 12.132 21.3371 12.132 21.9134C12.132 23.6612 13.3106 25.138 14.9145 25.5935V26.0871C14.9145 26.6634 15.3816 27.1305 15.9579 27.1305C16.5341 27.1305 17.0013 26.6634 17.0013 26.0871V25.5935C18.6053 25.138 19.7838 23.6612 19.7838 21.9134C19.7838 19.973 17.9328 18.9946 16.4455 18.2084Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14739_6744">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconMoney;
