import React, { useEffect, useState } from 'react';
import { setTheme } from 'common/reducer-actions/commonActions';
import { useDispatch } from 'store/StateProvider';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { theme as ind91Theme } from 'common/assets/styles/muitheme_ind91';
import { theme as dm99Theme } from 'common/assets/styles/muitheme_dm99';
import { theme as sun99Theme } from 'common/assets/styles/muitheme_sun99';

function getMuiTheme(webcode) {
  const webTheme = {
    IND91: ind91Theme,
    DM99: dm99Theme,
    SUN99: sun99Theme,
  };
  return webTheme[webcode];
}

// const theme = extendTheme(getMuiTheme(window.THEME_CODE));

const CustomThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [currentTheme, setCurrentTheme] = useState(extendTheme(getMuiTheme('SUN99')));

  useEffect(() => {
    if (window.THEME_CODE) {
      setTheme(dispatch, window.THEME_CODE);
      setCurrentTheme(extendTheme(getMuiTheme(window.THEME_CODE)));
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={currentTheme} defaultMode="dark">
        {children}
      </CssVarsProvider>
    </StyledEngineProvider>
  );
};

export default CustomThemeProvider;
