import { useMutation, useQuery } from '@tanstack/react-query';
import PublicApi from './api/publicApi';
import { formatResult } from 'common/helpers/helper';
import { QUERY_KEYS } from 'common/constants/query-key';
import {
  setLiveSupportUrl,
  setBaseSettingDetails,
  setExternalLinksList,
  setIsEmailOTPActive,
} from 'common/reducer-actions/publicActions';
import { useDispatch } from '../../store/StateProvider';

export function useGetCheckMaintenance() {
  const query = useQuery({
    queryKey: [QUERY_KEYS.PUBLIC.GET_CHECK_MAINTENANCE],
    queryFn: PublicApi.getCheckMaintenance,
    refetchInterval: 300000,
  });

  return formatResult(query, true);
}

export function useGetThirdPartyScriptInfo(memberReferralCode) {
  const query = useQuery({
    queryKey: [QUERY_KEYS.PUBLIC.GET_THIRD_PARTY_SCRIPT_INFO],
    enabled: false,
    queryFn: () => PublicApi.getThirdPartyScriptInfo(memberReferralCode),
  });

  return formatResult(query, true);
}

export function useLogDownloadAppIp(memberReferralCode) {
  const mutation = useMutation({
    mutationFn: () => PublicApi.logDownloadAppIp(memberReferralCode),
  });

  return formatResult(mutation);
}

export function useUpdateMetaEvt() {
  const mutation = useMutation({
    mutationFn: PublicApi.updateMetaEvt,
  });

  return formatResult(mutation);
}

export function useGetBrandSupportUrl() {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: [QUERY_KEYS.PUBLIC.BRAND_SUPPORT_URL],
    queryFn: async () => {
      const res = await PublicApi.getBrandSupportUrl();
      if (res) {
        setLiveSupportUrl(dispatch, res);
        return res;
      }
    },
  });

  return formatResult(query, true);
}

export function useGetBaseSetting(baseSettingKey) {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: [QUERY_KEYS.PUBLIC.BASE_SETTING],
    queryFn: async () => {
      const res = await PublicApi.getBaseSetting(baseSettingKey);
      if (res) {
        setBaseSettingDetails(dispatch, res);
        if (res.baseSettingKey === 84)
          setIsEmailOTPActive(dispatch, res.baseSettingValue === '1' ? true : false);

        return res;
      }
    },
  });
  return formatResult(query, true);
}

export function useGetExternalLinks(pageName) {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: [QUERY_KEYS.PUBLIC.EXTERNAL_LINKS],
    queryFn: async () => {
      const res = await PublicApi.getExternalLinks(pageName);
      if (res) {
        setExternalLinksList(dispatch, res);
        return res;
      }
    },
  });
  return formatResult(query, true);
}
