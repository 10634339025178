import { ACTIONS } from '../constants/actions';

const initialState = {
  bannerList: [],
  shortcutList: [],
};

export default function bannerReducer(banner = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.BANNER.BANNER_LIST:
      return { ...banner, bannerList: payload };
    case ACTIONS.BANNER.SHORTCUT_LIST:
      return { ...banner, shortcutList: payload };
    default:
      return banner;
  }
}
