import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import styles from './EmailPhoneTab.module.css';
import IconRegister2 from 'component/mobile/common/icon/IconRegister2';
import { borderRadius } from '@mui/system';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';

const useStyle = makeStyles((theme) => ({
  customTabs: {
    '& .MuiTabs-flexContainer': {
      backgroundColor: 'var(--mui-palette-bg-secondary)',
      borderRadius: 8,
      padding: 4,
    },
    '& button': {
      background: 'transparent',
      color: 'var(--mui-palette-text-tertiary)',
      fontSize: 13,
      textTransform: 'none',
      border: '1px solid transparent',
      borderRadius: 8,
      padding: 8,
      minHeight: 'auto',
    },
    '& button.Mui-selected': {
      color: 'var(--mui-palette-text-primary)',
      backgroundColor: 'var(--mui-palette-bg-primary)',
      border: '1px solid #2d346e',
      borderRadius: 10,
    },
    '& .MuiTabs-indicator': { display: 'none' },
  },
  customOSTabs: {
    '& .MuiTabs-flexContainer': {
      backgroundColor: 'var(--mui-palette-bg-secondary)',
      borderRadius: 8,
      padding: 4,
    },
    '& button': {
      background: 'transparent',
      color: 'var(--mui-palette-text-tertiary)',
      fontSize: 13,
      textTransform: 'none',
      border: '1px solid transparent',
      padding: 8,
      minHeight: 'auto',
    },
    '& button.Mui-selected': {
      borderBottom: '1px solid #ffffff',
    },
    '& .MuiTabs-indicator': { display: 'none' },
  },
  smallTab: { flex: 0, minWidth: 50 },
}));

const CustomTab = ({
  tab,
  tabValue,
  handleTabChange,
  selectedTabStyle,
  activeSMSPlatform,
  fromFunding = false,
  registerTab = false,
  referralRecordTab = false,
   customStyles = {},
}) => {
  const intl = useIntl();
  const classes = useStyle();

  const renderTabLabel = (tab) => {
    // if (tab.hideText) return null;

    return (
      <div className={styles.tabLabel}>
        {registerTab && tab.value === 'email' ? (
          <ThemeIcon IconComponent={IconRegister2} isActive={tabValue === tab.value} />
        ) : (
          tab.icon && tab.icon(tabValue === tab.value)
        )}
        {typeof tab.hideText === 'undefined' &&
          !tab.hideText &&
          (selectedTabStyle
            ? intl.formatMessage({ id: `installation.${tab.value}` })
            : fromFunding
            ? intl.formatMessage({ id: `funding.${tab.value}` })
            : referralRecordTab
            ? intl.formatMessage({ id: `referral_promotion.${tab.value}` })
            : registerTab && tab.value === 'email'
            ? intl.formatMessage({ id: 'login.register' })
            : intl.formatMessage({ id: `login.${tab.value}` }))}
      </div>
    );
  };

  return (
    <div style={{ ...customStyles }}> 
    <Tabs
      value={tabValue}
      onChange={handleTabChange}
      variant="fullWidth"
      scrollButtons="auto"
      classes={{ root: selectedTabStyle ? classes[selectedTabStyle] : classes.customTabs }}
    >
      {tab.map((tab) => (
        <Tab
          classes={tab.hideText ? { root: classes.smallTab } : {}}
          key={tab.value}
          label={renderTabLabel(tab)}
          value={tab.value}
        />
      ))}
    </Tabs>
  </div>
  );
};

export default memo(CustomTab);
