const IconUSDTBank = ({ isActive, color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17.1818L0 8.44949L3.27273 1.3418H14.7273L18 8.44949L9 17.1818Z"
        fill={color}
      />
      <path
        d="M14.4016 8.48657C14.4016 7.8898 12.5848 7.39134 10.1587 7.26949V5.99426H13.2444V3.50195H4.75871V5.99426H7.84442V7.26949C5.41828 7.39134 3.60156 7.8898 3.60156 8.48657C3.60156 9.08334 5.41828 9.5818 7.84442 9.70365V14.302H10.1587V9.70365C12.5848 9.5818 14.4016 9.08334 14.4016 8.48657ZM9.00156 9.31734C6.44556 9.31734 4.37299 8.89918 4.37299 8.38272C4.37299 7.94795 5.84899 7.58242 7.84442 7.47857V8.86872C8.21471 8.89365 8.60042 8.90195 9.00156 8.90195C9.40271 8.90195 9.78842 8.89365 10.1587 8.86872V7.47857C12.1541 7.58242 13.6301 7.94657 13.6301 8.38272C13.6301 8.89918 11.5576 9.31734 9.00156 9.31734Z"
        fill="#1C2257"
      />
    </svg>
  );
};

export default IconUSDTBank;
