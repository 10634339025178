import { ACTIONS } from '../constants/actions';

/**
 * Set user details action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Wallet} payload Action payload
 */
export const setDepositChannelsResult = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.WALLET.GETDEPOSITCHANNELS,
  });

// export const setDepositRates = (dispatch, payload) =>
//   dispatch({
//     payload,
//     type: ACTIONS.WALLET.DEPOSITRATE,
//   });

// export const setBlockIframe = (dispatch, payload) =>
//   dispatch({
//     payload,
//     type: ACTIONS.WALLET.BLOCKIFRAME,
//   });

// export const setDepositData = (dispatch, payload) =>
//   dispatch({
//     payload,
//     type: ACTIONS.WALLET.DEPOSITDATA,
//   });

// export const setPaymentUrl = (dispatch, payload) =>
//   dispatch({
//     payload,
//     type: ACTIONS.WALLET.PAYMENT_URL,
//   });

export const setWithdrawData = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.WALLET.WITHDRAWAL_DATA,
  });

export const setWithdrawalInfo = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.WALLET.WITHDRAWAL_INFO,
  });

export const setAttemptsLeft = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.WALLET.WITHDRAWAL_ATTEMPTS_LEFT,
  });

export const setRates = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.WALLET.WITHDRAWAL_RATES,
  });

export const setAttemptsDaily = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.WALLET.WITHDRAWAL_ATTEMPTS_DAILY,
  });

export const setWithdrawInfo = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.WALLET.WITHDRAW_INFO,
  });

export const setBalanceData = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.WALLET.BALANCE_DATA,
  });

export const setHistoryData = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.WALLET.HISTORY_DATA,
  });
