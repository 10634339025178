import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import makeStyles from '@mui/styles/makeStyles';

// const useStyle = makeStyles((theme) => ({
//   icon: {
//     filter: 'brightness(2)',
//   },
// }));

const ProfileMenuItem = ({
  route,
  label,
  icon,
  handleClose,
  handleCloseGame,
  labelClass = null,
}) => {
  // const classes = useStyle();
  return (
    <>
      {handleCloseGame ? (
        <div onClick={handleCloseGame}>
          <MenuItem onClick={handleClose} disableRipple>
            <ListItemIcon>{icon}</ListItemIcon>
            <span className={labelClass}>{label}</span>
          </MenuItem>
        </div>
      ) : (
        <Link to={route}>
          <MenuItem onClick={handleClose} disableRipple>
            <ListItemIcon>{icon}</ListItemIcon>
            <span className={labelClass}>{label}</span>
          </MenuItem>
        </Link>
      )}
    </>
  );
};

export default memo(ProfileMenuItem);
