import { ACTIONS } from '../constants/actions';

const initialState = {
  paymentList: [],
  depositRates: null,
  blockIframe: false,
  depositData: [],
  paymentUrl: '',
  withdrawData: [],
  withdrawalInfo: {
    withdrawChannelId: 0,
    withdrawChannelType: '',
    withdrawPayTypeName: '',
    amount: '',
    walletId: 1,
    bindingId: 0,
    ifsc: '',
    accountNo: '',
    accountName: '',
  },
  attemptsLeft: undefined,
  rates: undefined,
  attemptsDaily: undefined,
  withdrawInfo: null,
  balanceData: null,
  historyData: null,
};

export default function walletReducer(wallet = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.WALLET.GETDEPOSITCHANNELS:
      return { ...wallet, paymentList: payload.data.paylist, depositRates: payload.data.rates };
    case ACTIONS.WALLET.DEPOSITRATE:
      return { ...wallet, depositRates: payload.data };
    case ACTIONS.WALLET.BLOCKIFRAME:
      return { ...wallet, blockIframe: payload };
    case ACTIONS.WALLET.DEPOSITDATA:
      return { ...wallet, depositData: payload.data };
    case ACTIONS.WALLET.PAYMENT_URL:
      return { ...wallet, paymentUrl: payload.data };
    case ACTIONS.WALLET.WITHDRAWAL_DATA:
      return { ...wallet, withdrawData: payload };
    case ACTIONS.WALLET.WITHDRAWAL_INFO:
      return {
        ...wallet,
        withdrawalInfo: {
          ...wallet.withdrawalInfo,
          withdrawChannelId: payload.withdrawChannelId,
          withdrawChannelType: payload.withdrawChannelType,
        },
      };
    case ACTIONS.WALLET.WITHDRAWAL_ATTEMPTS_LEFT:
      return { ...wallet, attemptsLeft: payload };
    case ACTIONS.WALLET.WITHDRAWAL_RATES:
      return { ...wallet, rates: payload };
    case ACTIONS.WALLET.WITHDRAWAL_ATTEMPTS_DAILY:
      return { ...wallet, attemptsDaily: payload };
    case ACTIONS.WALLET.WITHDRAW_INFO:
      return { ...wallet, withdrawInfo: payload };
    case ACTIONS.WALLET.BALANCE_DATA:
      return { ...wallet, balanceData: payload };
    case ACTIONS.WALLET.HISTORY_DATA:
      return { ...wallet, historyData: payload };
    default:
      return wallet;
  }
}
