import { ACTIONS } from '../constants/actions';

const initialState = {
  filterList: {
    date: new Date(),
    endDate: new Date(),
    level: null,
    getCommissionData: true,
  },
  detailedData: null,
  activeGameList: null,
  commissionList: null,
};

export default function affiliateReducer(affiliate = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.AFFILIATE.FILTER:
      return { ...affiliate, filterList: payload.data };
    case ACTIONS.AFFILIATE.DETAILED_DATA:
      return { ...affiliate, detailedData: payload.data };
    case ACTIONS.AFFILIATE.ACTIVEGAMELIST:
      return { ...affiliate, activeGameList: payload };
    case ACTIONS.AFFILIATE.COMMISSIONLIST:
      return { ...affiliate, commissionList: payload };
    default:
      return affiliate;
  }
}
