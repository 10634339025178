const IconLogout = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6354 10C14.6354 10.4315 14.2857 10.7812 13.8542 10.7812H8.05797L9.79719 12.5205C10.1023 12.8256 10.1023 13.3203 9.79719 13.6254C9.49203 13.9305 8.99742 13.9304 8.69234 13.6254L5.61941 10.5524C5.31387 10.2468 5.31465 9.75238 5.61941 9.44754L8.69234 6.37461C8.99746 6.06957 9.49211 6.06953 9.79719 6.37461C10.1023 6.67973 10.1023 7.17441 9.79719 7.47949L8.05797 9.21875H13.8542C14.2857 9.21875 14.6354 9.56852 14.6354 10ZM20 5.79578V14.2042C20 16.8753 18.1886 18.2318 18.0712 18.3982C17.0384 19.4312 15.665 20 14.2042 20H5.79578C4.335 20 2.96168 19.4311 1.92879 18.3982C1.81254 18.2335 0 16.8755 0 14.2042V5.79578C0 3.12477 1.81145 1.76813 1.92883 1.60176C2.96168 0.568867 4.33504 0 5.79578 0H14.2042C15.665 0 17.0384 0.568867 18.0712 1.6018C18.1873 1.76633 20 3.12441 20 5.79578ZM18.4375 5.79578C18.4375 3.8384 17.0851 2.87184 16.9663 2.70664C16.2286 1.96879 15.2476 1.5625 14.2042 1.5625H5.79578C4.75238 1.5625 3.77145 1.96883 3.03367 2.7066C2.91055 2.87777 1.5625 3.82801 1.5625 5.79578V14.2042C1.5625 16.1616 2.91465 17.1279 3.03363 17.2934C3.77145 18.0312 4.75238 18.4375 5.79578 18.4375H14.2042C15.2476 18.4375 16.2286 18.0312 16.9663 17.2934C17.0894 17.1222 18.4375 16.172 18.4375 14.2042V5.79578Z"
        fill={color}
      />
    </svg>
  );
};

export default IconLogout;
