import { CopyToClipboard } from 'react-copy-to-clipboard';
import { memo, useCallback } from 'react';
import { useStore } from 'store/StateProvider';
import NumFormat from 'component/shared/numformat/NumFormat';
import IconCopy from 'component/mobile/common/icon/IconCopy';
import { useGetVaultLog } from 'services/query/walletQuery';
import styles from './VaultHistory.module.css';
import moment from 'moment';
import { enqueueSnackbarMsg } from 'common/helpers/helper';

const VaultHistory = ({ intl }) => {
  const [store] = useStore();
  const [ ]= useGetVaultLog();
  const historyData = store.wallet?.historyData;

  const handleCopy = useCallback(() => {
    return enqueueSnackbarMsg('Copied');
  }, [enqueueSnackbarMsg]);

  return (
    <>
      {historyData != null && historyData.length > 0 &&
        historyData.map((item, i) => {
          return (
            <div key={i} className={styles.box}>
              <div className={styles.dateRow}>
                <span>{moment(item.created).format('DD.MM.YYYY HH:mm:ss')}</span>
                <span className={styles.result}>
                  {item.balanceChangeType === 32 && <div className={styles.success}>Deposit</div>}
                  {item.balanceChangeType === 33 && <div className={styles.fail}>Withdrawal</div>}
                  {item.balanceChangeType === 21 && (
                    <div className={styles.fail}>Bonus Expired</div>
                  )}
                </span>
              </div>
              <div className={styles.content}>
                <div className={styles.id}>
                  {item.transactionId}
                  <div className={styles.idRight}>
                    <CopyToClipboard
                      className={styles.copyLink}
                      text={item.transactionId}
                      onCopy={handleCopy}
                    >
                      <div>
                        <IconCopy width={20} height={20} />
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className={styles.balanceChanges}>
                  <div>
                    Vault Balance
                    <NumFormat
                      className={styles.numchange}
                      value={item.afterVaultBalance || 0.0}
                      prefix={' ' + window.CURRENCY_CODE}
                    />
                  </div>
                  <div>
                    {item.afterVaultBalance - item.beforeVaultBalance > 0 && (
                      <span className={styles.num}>+</span>
                    )}
                    <NumFormat
                      className={styles.num}
                      value={item.afterVaultBalance - item.beforeVaultBalance || 0.0}
                      prefix={' ' + window.CURRENCY_CODE}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default memo(VaultHistory);