import { ACTIONS } from '../constants/actions';

const initalState = {
  verifyCountdownTimer: 0,
  removeTimer: false,
  timer: 0,
  isCountdown: false,
};

export default function countdownReducer(countdown = initalState, { type, payload }) {
  switch (type) {
    case ACTIONS.COUNTDOWN.VERIFY_COUNTDOWN_TIMER:
      return { ...countdown, verifyCountdownTimer: payload };
    case ACTIONS.COUNTDOWN.REMOVED_TIMER:
      return { ...countdown, removeTimer: payload };
    case ACTIONS.COUNTDOWN.TIMER:
      return { ...countdown, timer: payload };
    case ACTIONS.COUNTDOWN.IS_COUNTDOWN:
      return { ...countdown, isCountdown: payload };
    default:
      return countdown;
  }
}
