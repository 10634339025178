const IconSideMenu = ({ isActive, color, width = 28, height = 28 }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 28 28`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="12.4117"
          width="14.8235"
          height="3.17647"
          rx="1.58824"
          fill={!isActive ? color : 'white'}
        />
        <rect
          y="5"
          width="23.2941"
          height="3.17647"
          rx="1.58824"
          fill={!isActive ? color : 'white'}
        />
        <rect
          y="19.8235"
          width="23.2941"
          height="3.17647"
          rx="1.58824"
          fill={!isActive ? color : 'white'}
        />
        {!isActive ? (
          <path
            d="M27.2002 18.773L22.8331 13.9939L27.2002 9.21471C27.6392 8.73434 27.6392 7.95834 27.2002 7.47796C26.7613 6.99758 26.0522 6.99758 25.6132 7.47796L20.4469 13.1317C20.0079 13.612 20.0079 14.388 20.4469 14.8684L25.6132 20.5221C26.0522 21.0025 26.7613 21.0025 27.2002 20.5221C27.6279 20.0417 27.6392 19.2534 27.2002 18.773Z"
            fill={!isActive ? color : 'white'}
          />
        ) : (
          <path
            d="M22.14 15.6407L19.0594 18.7214C18.8646 18.9161 18.6168 19.0135 18.3158 19.0135C18.0148 19.0135 17.7669 18.9161 17.5722 18.7214C17.3774 18.5266 17.28 18.2788 17.28 17.9778C17.28 17.6768 17.3774 17.4289 17.5722 17.2342L20.6528 14.1535L17.5722 11.0994C17.3774 10.9047 17.28 10.6568 17.28 10.3558C17.28 10.0548 17.3774 9.80695 17.5722 9.6122C17.7669 9.41745 18.0148 9.32007 18.3158 9.32007C18.6168 9.32007 18.8646 9.41745 19.0594 9.6122L22.14 12.6929L25.1941 9.6122C25.3889 9.41745 25.6368 9.32007 25.9377 9.32007C26.2387 9.32007 26.4866 9.41745 26.6813 9.6122C26.8938 9.82466 27 10.077 27 10.3691C27 10.6612 26.8938 10.9047 26.6813 11.0994L23.6007 14.1535L26.6813 17.2342C26.8761 17.4289 26.9735 17.6768 26.9735 17.9778C26.9735 18.2788 26.8761 18.5266 26.6813 18.7214C26.4689 18.9338 26.2166 19.0401 25.9245 19.0401C25.6323 19.0401 25.3889 18.9338 25.1941 18.7214L22.14 15.6407Z"
            fill={!isActive ? color : 'white'}
          />
        )}
      </svg>
    );
  };
  
  export default IconSideMenu;