import React, { useState, useCallback, useEffect } from 'react';
import { useVerifyPassword } from 'services/query/accountQuery';
import { useStore } from 'store/StateProvider';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import IconPassword from 'component/mobile/common/icon/IconPassword';
import CommonPrompt from 'component/mobile/common/commonPrompt/CommonPrompt';
import { AiFillEye } from '@react-icons/all-files/ai/AiFillEye';
import { AiFillEyeInvisible } from '@react-icons/all-files/ai/AiFillEyeInvisible';
import StyledTextField from 'component/shared/styledTextField/StyledTextField';
import { MobileImages } from 'common/constants/image-resource';
import { enqueueSnackbarMsg } from 'common/helpers/helper';

const VaultPromptPassword = ({ navigate, showPasswordPrompt, setShowPasswordPrompt, intl }) => {
  const [lock, setLock] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [store] = useStore();
  const [formData, setFormData] = useState({
    password: '',
  });

  const [{ data: pData }, submitPassword] = useVerifyPassword();
  // const showPasswordPrompt = store.common?.showPasswordPrompt || false;

  useEffect(() => {
    if (pData && pData.data) {
      setShowPasswordPrompt(false);
    }
  }, [pData]);

  const submitEvent = useCallback(
    async (e) => {
      e.preventDefault();
      if (formData.password === '') {
        enqueueSnackbarMsg('Please fill in your password', 'error');
        return;
      }

      setFormData({ password: formData.password });
      submitPassword({ password: formData.password });
    },
    [formData],
  );

  const cancelEvent = () => {
    navigate(-1);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleChange = (prop) => (e) => {
    setFormData((prevData) => ({ ...prevData, [prop]: e.target.value }));
  };
  return (
    <>
      <CommonPrompt
        open={showPasswordPrompt}
        titleImage={MobileImages.vault.password_icon}
        title={intl.formatMessage({ id: 'vault.vault_title' })}
        cancelEvent={cancelEvent}
        submitEvent={submitEvent}
        message={intl.formatMessage({id: 'vault.vault_message'})}
        submitText={intl.formatMessage({id: 'common.verifyText'})}
        textbox={
          <StyledTextField
            required
            value={formData.password}
            onChange={handleChange('password')}
            placeholder={intl.formatMessage({ id: 'login.password' })}
            InputProps={{
              type: showPassword ? 'text' : 'password',
              startAdornment: (
                <InputAdornment position="start">
                  <ThemeIcon IconComponent={IconPassword} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <ThemeIcon IconComponent={AiFillEye} />
                    ) : (
                      <ThemeIcon IconComponent={AiFillEyeInvisible} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        }
      />
    </>
  );
};
export default VaultPromptPassword;