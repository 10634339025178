import React, { useState, useEffect, useRef } from 'react';
import flatten from 'flat';
import merge from 'deepmerge';
import { IntlProvider } from 'react-intl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import msg_zh from 'common/lang/zh.js';
import msg_en from 'common/lang/en.js';
import { API } from 'common/constants/api';
import { setLocalStorage } from '../../../common/helpers/localStorage';
import styles from './IntlWrapper.module.css';
import IconChecked from 'component/mobile/common/icon/IconChecked';
import IconUncheck from 'component/mobile/common/icon/IconUncheck';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';

export const Context = React.createContext();
const supportedLanguage = {
  'en-US': { text: 'English', messagesDataKey: 'en' },
  'zh-CN': { text: 'Chinese', messagesDataKey: 'zh' },
};
const defaultLang = 'zh-CN';
const messagesData = {
  en: flatten(msg_en),
  zh: flatten(merge.all([msg_en, msg_zh])),
};

const Wrapper = (props) => {
  const intlRef = useRef(null);
  const [locale, setLocale] = useState(defaultLang);
  const [messages, setMessages] = useState(
    messagesData[supportedLanguage[defaultLang].messagesDataKey],
  );
  const [showMenu, setShowMenu] = useState(false);

  const handleClickOutSide = (event) => {
    if (intlRef.current && !intlRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('locale'));
    if (items && items in supportedLanguage) {
      setLocale(items);
      setMessages(messagesData[supportedLanguage[items].messagesDataKey]);
    }
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, []);

  const selectLanguage = (event, value) => {
    event.stopPropagation();
    const lang = value;

    if (lang in supportedLanguage) {
      setLocalStorage('locale', lang);
      setLocale(lang);
      setMessages(messagesData[supportedLanguage[lang].messagesDataKey]);
      toggleLanMenu();
    }
  };

  const toggleLanMenu = () => {
    setShowMenu((currentState) => !currentState);
  };
  const MenuComponent = ({ menuText, menuFlagStr, list = false, langKey }) => {
    return (
      <div className={styles.menuItemContainer}>
        <img
          src={API.CDN_REACT + '/lan-' + menuFlagStr + '.png'}
          alt=""
          width={20}
          height={20}
          className={styles.flag}
        />
        <span className={styles.menuText}>{menuText}</span>
        {list && (
          <div className={styles.listIcon}>
            {supportedLanguage[langKey].text === supportedLanguage[locale].text ? (
              <IconChecked />
            ) : (
              <IconUncheck />
            )}
          </div>
        )}
      </div>
    );
  };

  const changeLangComponent = () => {
    return (
      <div ref={intlRef} className={styles.container}>
        {showMenu && (
          <div className={styles.menuContainer}>
            <div className={styles.langPadding}>
              {Object.entries(supportedLanguage).map(
                ([key]) => (
                  // locale !== key && (
                  <div
                    value={key}
                    key={key}
                    onClick={(e) => selectLanguage(e, key)}
                    className={styles.menuItem}
                  >
                    <MenuComponent
                      menuText={supportedLanguage[key].text}
                      menuFlagStr={supportedLanguage[key].messagesDataKey}
                      list={true}
                      langKey={key}
                    />
                  </div>
                ),
                // ),
              )}
            </div>
          </div>
        )}
        <div className={showMenu ? styles.languageOptionExpand : styles.languageOptionContainer}>
          <div
            className={showMenu ? styles.toggleMenuExpand : styles.toggleMenuContainer}
            onClick={toggleLanMenu}
          >
            <MenuComponent
              menuText={supportedLanguage[locale].text}
              menuFlagStr={supportedLanguage[locale].messagesDataKey}
            />
            <ThemeIcon
              IconComponent={KeyboardArrowDownIcon}
              className={styles.icon}
              style={{
                transform: `${showMenu ? 'rotate(180deg)' : 'rotate(0deg)'}`,
                fill: '#565883',
              }}
            />
            {/* <KeyboardArrowDownIcon
              className={styles.icon}
              style={{
                transform: `${showMenu ? 'rotate(180deg)' : 'rotate(0deg)'}`,
              }}
            /> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Context.Provider value={{ changeLangComponent, locale }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
