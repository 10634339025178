import errorReducer from '../common/reducers/errorReducer';
import userReducer from '../common/reducers/userReducer';
import messageReducer from '../common/reducers/messageReducer';
import ipAddressReducer from '../common/reducers/ipAddressReducer';
import commonReducer from 'common/reducers/commonReducer';
import referralReducer from 'common/reducers/referralReducer';
import popupReducer from 'common/reducers/popupReducer';
import bonusReducer from 'common/reducers/bonusReducer';
import walletReducer from 'common/reducers/walletReducer';
import affiliateReducer from 'common/reducers/affiliateReducer';
import promotionReducer from 'common/reducers/promotionReducer';
import gameReducer from 'common/reducers/gameReducer';
import bannerReducer from 'common/reducers/bannerReducer';
import publicReducer from 'common/reducers/publicReducer';
import countdownReducer from 'common/reducers/countdownReducer';

/**
 * Function is super basic analog to Redux's combineReducers().
 * @param {Object} state Current application state.
 * @param {Action} action Dispatched action.
 * @returns New application state.
 */

export default function appReducer(state, action) {
  const {
    error,
    user,
    message,
    ipAddress,
    common,
    referral,
    popup,
    bonus,
    wallet,
    affiliate,
    promotion,
    game,
    banner,
    publics,
    countdown,
  } = state;
  const updatedState = {
    common: commonReducer(common, action),
    error: errorReducer(error, action),
    user: userReducer(user, action),
    ipAddress: ipAddressReducer(ipAddress, action),
    message: messageReducer(message, action),
    referral: referralReducer(referral, action),
    popup: popupReducer(popup, action),
    bonus: bonusReducer(bonus, action),
    wallet: walletReducer(wallet, action),
    affiliate: affiliateReducer(affiliate, action),
    promotion: promotionReducer(promotion, action),
    game: gameReducer(game, action),
    banner: bannerReducer(banner, action),
    publics: publicReducer(publics, action),
    countdown: countdownReducer(countdown, action),
  };
  return updatedState;
}
