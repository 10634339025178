import { ACTIONS } from '../constants/actions';

/**
 * Set user details action
 * @param {Function} dispatch Action dispatcher from store
 * @param {User} payload Action payload
 */
export const setUserDetails = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.USER.USER_DETAILS_SET,
  });

/**
 * User Balance action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setUserBalance = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.USER.USER_BALANCE,
  });

/**
 * User logout action
 * @param {Function} dispatch Action dispatcher from store
 */
export const userLogout = (dispatch) =>
  dispatch({
    type: ACTIONS.USER.USER_LOGOUT,
  });

/**
 * Spin Chance action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setSpinChance = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.USER.SPIN_CHANCE,
  });

export const setUserInfo = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.USER.USER_INFO,
  });

export const setOpenVerifyPopUp = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.USER.VERIFY_POPUP,
  });

export const setRemovePhoneorEmail = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.USER.REMOVE_PHONE,
  });

export const setActiveSMSPlatform = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.USER.ACTIVE_SMS_PLATFORM,
  });
