export const API = {
  HOST: window.APP_API_HOST || 'http://localhost:4000',
  CDN: window.APP_API_UPLOAD_URL || 'http://localhost:5000/uploads/',
  CDN_REACT: window.APP_CDN_REACT || 'http://localhost:3000/',
  APIBASE: '/v1/api',
  ACCOUNT: {
    SESSION_ACTIVE: '/Accounts/session-active',
    PING: '/Accounts/ping',
    LOGIN: '/Accounts/authenticate',
    THIRD_PARTY_LOGIN: '/Accounts/third-party-login',
    THIRD_PARTY_UPDATE: '/Accounts/third-party-update',
    CHECK_THIRD_PARTY_REGISTERED: '/Accounts/check-third-party-registered',
    REGISTER: '/Accounts/register',
    FORGOT_PASSWORD: '/Accounts/forgot-password',
    RESET_PASSWORD: '/Accounts/reset-password',
    CURRENT_USER: '/Accounts/current',
    REVOKE_TOKEN: '/Accounts/revoke-token',
    REFRESH_TOKEN: '/Accounts/refresh-token',
    UPDATE: '/Accounts/update',
    SUMMARY: '/Accounts/summary?startDate=:startDate&endDate=:endDate',
    SUMMARY_DEFAULT: '/Accounts/summary',
    VERIFY: '/Accounts/verify-email',
    VERIFY_EMAIL_SMS:'/Accounts/verify-email-sms',
    REQUEST_VERIFY: '/Accounts/request-verify-email-new',
    REQUEST_VERIFY_EMAIL_OTP: '/Accounts/request-email-verification-code',
    SENDSMS: '/Accounts/request-verify-sms',
    VERIFYSMS: '/Accounts/verify-sms',
    EMAIL_VERIFICATION_CODE: '/Accounts/request-forgot-password-code',
    VALIDATE_RESET_CODE: '/Accounts/validate-reset-code',
    UNSUBSCRIBE: '/Accounts/unsubscribe',
    SUBSCRIBE: '/Accounts/subscribe',
    CAPTCHAV1: '/Accounts/captcha/v1',
    CAPTCHAV2: '/Accounts/captcha/v2',
    CAPTCHAV1_VERIFY: '/Accounts/captcha/v1/verify',
    CAPTCHAV2_VERIFY: '/Accounts/captcha/v2/verify',
    VERIFY_PASSWORD: '/Accounts/verify-password',
    REMOVE_MEMBER_PHONE_OR_EMAIL: '/Accounts/remove-member-phone-or-email',
  },
  WALLET: {
    BALANCE: '/Wallet/balance',
    DEPOSIT_CHANNEL: '/Wallet/deposit-channel',
    DEPOSIT_CHANNEL_SETTINGS: '/Wallet/deposit-settings',
    DEPOSIT: '/Wallet/deposit',
    WITHDRAW: '/Wallet/withdraw',
    WITHDRAW_BINDING_FILTER: '/Wallet/binding-filter',
    WITHDRAW_BINDING_ADD: '/Wallet/binding-add',
    WITHDRAW_BINDING_GET: '/Wallet/binding-get',
    WITHDRAW_BINDING_LIST: '/Wallet/get-withdraw-binding-list',
    WITHDRAW_BINDING_DEL: '/Wallet/binding-delete',
    WITHDRAW_INFO: '/Wallet/withdraw-info',
    DEPOSIT_CHANNEL_TYPE: '/Wallet/deposit-channel-type',
    GET_DEPOSIT: '/Wallet/get-deposit',
    VAULT: '/Wallet/vault',
    VAULT_LOG: '/Wallet/vaultlog',
  },
  BONUS: {
    GET_AVAILABLE_BONUS: '/Bonus/get-available-bonus',
    VERIFY_CODE: '/Bonus/verify-code',
    DAILY_BONUS: '/Bonus/get-member-checkin-info',
    UPDATE_DAILY_BONUS: '/Bonus/update-member-checkin-info',
    REFERRAL_BONUS: '/Bonus/get-member-referral-bonus-info',
    GET_VIP_DATA: '/Bonus/get-vip-data',
    GET_CLAIMABLE_BONUS: '/Bonus/get-claimable-bonus',
    CLAIM_BONUS: '/Bonus/claim-bonus',
    UPDATE_LAST_EFFECT: '/Bonus/update-last-shown-effect',
    CLAIM_WEEK_BONUS: '/Bonus/update-member-weekly-checkin-info',
    CLAIM_MONTH_BONUS: '/Bonus/update-member-monthly-checkin-info',
    CLAIM_LEVEL_BONUS: '/Bonus/update-member-levelup-checkin-info',
    GET_RAFFLE_DATA: '/LuckyWheel/get-spin-details',
    UPDATE_MEMBER_RAFFLE: '/LuckyWheel/spin-lucky-wheel',
    REDEEM_VOUCHER: '/Bonus/claim-voucher',
    GET_CLAIMABLE_REWARD: '/Bonus/get-claimable-reward',
    CLAIM_REWARD: '/Bonus/claim-reward',
    CLAIM_REWARD_ACTIVE: '/Bonus/claimable-reward/active',
  },
  MESSAGE: {
    GET_MESSAGE_LIST: '/Message',
    GET_MESSAGE_LIST_BY_CATEGORY: '/Message/Channel/:id',
    GET_MESSAGE_UNREAD_COUNT: '/Message/GetMessageUnreadCount',
    DELETE_MESSAGE: '/Message/Delete',
    DELETE_MESSAGE_BY_CATEGORY: '/Message/DeleteByCategory',
    GET_ANNOUNCEMENT_LIST: '/Message/Announcement/:id',
  },
  GAME: {
    LAUNCH_GAME: '/Game/launch',
    LAUNCH_DEMO_GAME: '/Game/launchDemo',
    SUBGAME: '/Game/subgamelist',
    FEATURED_SUBGAME: '/Game/subgamelist/featured',
    ADD_RECENT_GAME_PLAY: '/Game/AddRecentGamePlayed',
    GET_RECENT_GAME_PLAY: '/Game/GetRecentGamePlayed',
    SEARCH_BY_NAME: '/Game/search',
    GET_JACKPOT: '/Game/GetJackpot',
    GET_GAME_JACKPOT: '/Game/GetGameJackpot',
    GET_LIMIT_SETTINGS: '/Game/GetLimitSettings',
    UPDATE_LIMIT_SETTING: '/Game/UpdateLimitSetting',
    GAME_LIST: '/Game/gamelist',
    FEATURED_GAME_LIST: '/Game/gamelist/featured',
    GAME_CACHE_VERSION: '/Game/GetGameListVersion',
    GET_FAV_GAMELIST: '/Accounts/get-favourite-game-list',
    GET_FAV_GAMEIDLIST: '/Accounts/get-favourite-gameid-list',
    TOGGLE_FAV_GAMELIST: '/Accounts/toggle-favourite-game-list',
    GET_GAME_CATEGORY: '/Game/getmaincategory',
    GET_MAIN_PAGE_CATEGORY_GAME: '/Game/mainpagegames',
    GET_WIN_LIST: '/Game/GetLastWinBets',
    GET_AVAILABLE_FREE_SPIN: '/Game/GetAvailableFreeSpin',
  },
  TRANSACTION: {
    GET_FILTER: '/Transaction/GetTransactionFilter',
    GET: '/Transaction/GetTransaction',
  },
  BANNER: {
    GET: '/Banner/GetBannerList/:type',
    BANNER_CACHE_VERSION: '/Banner/GetBannerListVersion',
  },
  POPUPBANNER: {
    GET: '/Banner/GetPopupBanner/:type',
    POPUP_BANNER_CACHE_VERSION: '/Banner/GetPopupBannerListVersion',
  },
  EVENTURL: {
    GET: '/Banner/GetEventUrl',
  },
  PROMOTION: {
    GET: '/Promotion/GetPromotionList',
    GET_DETAIL: '/Promotion/GetPromotionDetail/:id',
  },
  AFFILIATE: {
    GET_NAME: '/Affiliate/GetName/:code',
    GET_NAME_DOMAIN: '/Affiliate/GetNameByDomain/:code',
    REFERRED_MEMBER_LIST: '/Referral/referred-members?startDate=:startDate&endDate=:endDate',
    GET_COMMISSION_HISTORY: '/Agent/GetGeneralAgentCommissionDataNew',
    GET_DETAILED_DATA: '/Agent/FetchDetailedData',
    GET_TEAM_DETAILS: '/Agent/GetTeamDetails',
    GET_LEVELBETTING_DETAILS: '/Agent/GetLevelBettingDetails',
    GET_COMMISSION_RATE: '/Agent/GetCommissionRate',
  },
  PUBLIC: {
    PING: '/pub/ping',
    MAINTENANCE: '/pub/maintenance',
    CHECK_MAINTENANCE: '/pub/checkMaintenance',
    GET_METAPIXEL_INFO: '/Accounts/getMetaPixelInfo/:code',
    LOG_DOWNLOADAPPIP: '/pub/memberReferral?referralCode=:code',
    UPDATE_META_EVT: '/Accounts/update-meta-evt-sent',
    GETBASESETTINGBYKEY:'/BaseSetting/BaseSettingByBaseSettingKey?baseSettingKey={baseSettingKey}&webCode={webCode}',
    GET_BRAND:'/Brand/GetBrand',
    GET_BY_PAGE: '/ExternalLink/GetExternalLinks/:page',
  },
  NEWS: {
    GET: '/News/GetNews/:platform',
    DETAIL: '/News/GetNews/Detail/:id',
  },
  ACTIVITY: {
    VIDEO: '/UserActivity/recordVideoAdActivity',
  },
  SMS: {
    CHECK_SMS_PLATFORM: '/Sms/CheckActiveSMSPlatform',
  },
  FREESPIN: {
    ELIGIBILITY: '/FreeSpin/check-freespin-eligibility',
    GIVE_SPIN: '/FreeSpin/give-freespin-opengame',
    CHECK_BONUS_VALIDITY: '/FreeSpin/check-freespin-bonus-validity',
  },
  BLACKLIST: {
    ADD: '/BlackList/Add',
    CHECK_RESTRICTED_TYPE: '/BlackList/CheckRestrictedTypeByMemberId',
  },
  INVITE_REFERRAL_SPIN: {
    PAGE_DETAIL: '/Bonus/invite-detail',
    SPIN: '/Bonus/spin-invite-bonus',
    CLAIM: '/Bonus/claim-invite-bonus',
    LUCKY_USER_LIST: '/Bonus/lucky-user-invite-bonus',
    REGISTER_RECORD_LIST: '/Bonus/register-record-invite-bonus',
  },
  DAILYLOGINBONUS: {
    ACTIVE: '/DailyLoginBonus/active',
    DETAILS: '/DailyLoginBonus/detail',
    CLAIM: '/DailyLoginBonus/claimbonus',
  },
  DAILYWEEKLYBONUS: {
    GET_CLAIMABLE_REWARD: '/Bonus/get-claimable-reward',
    CLAIM: '/Bonus/claim-reward',
    ACTIVE_BONUS: '/Bonus/claimable-reward/active/list',
  },
  SHORTCUT: {
    GET_SHORTCUT: '/Shortcut/GetShortcut',
    SHORTCUT_CACHE_VERSION: '/Shortcut/GetHomeShortcutListVersion',
  },
};
