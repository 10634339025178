import React from 'react';
import { API } from 'common/constants/api';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//for SEO microdata control
const StructuredData = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              url: window.APP_HOST,
              potentialAction: [
                {
                  '@type': 'SearchAction',
                  target: {
                    '@type': 'EntryPoint',
                    urlTemplate: window.APP_HOST + '/search?q={search_term_string}',
                  },
                  'query-input': 'required name=search_term_string',
                },
              ],
            })}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: window.DOMAIN_URL,
              url: window.APP_HOST,
              description:
                '探索终极在线赌场体验，尽在 太阳城亚洲——中国玩家的首选目的地！在这里，您可以畅玩各种刺激的老虎机和经典赌场游戏，享受真人荷官带来的互动体验，并通过丰厚的奖金和累积奖池赢取巨额奖金。立即加入我们，体验高品质的娱乐享受和为中国玩家量身定制的精彩投注选项！',
              email: window.CS_EMAIL,
              logo: `${API.CDN_REACT}/logo_v2.png`,
              image: `${API.CDN_REACT}/logo_v2.png`,
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'User Feedback',
                url: window.APP_HOST,
                email: window.CS_EMAIL,
                //   telephone: '+',
              },
              sameAs: [
                window.APP_HOST,
                // 'https://www.facebook.com/' + window.FACEBOOK_URL,
                // 'http://m.me/' + window.MESSENGER_URL,
                // 'https://www.instagram.com/' + window.INSTAGRAM_URL,
                // 'https://t.me/' + window.TELEGRAM_URL,
                // window.LIVECHAT_URL,
              ],
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'ItemList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  url: window.APP_HOST,
                  name: '太阳城亚洲 | 中国在线赌场',
                  image: `${API.CDN_REACT}/logo_v2.png`,
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  url: window.APP_HOST,
                  name: 'casino game',
                  image: `${API.CDN_REACT}/seo/casino_teenpatti.png`,
                },
                {
                  '@type': 'ListItem',
                  position: 3,
                  url: window.APP_HOST,
                  name: 'slot game',
                  image: `${API.CDN_REACT}/seo/slot_jili.png`,
                },
                {
                  '@type': 'ListItem',
                  position: 4,
                  url: window.APP_HOST,
                  name: 'aviator game',
                  image: API.CDN_REACT + '/seo/slot_aviator.png',
                },
                {
                  '@type': 'ListItem',
                  position: 5,
                  url: window.APP_HOST,
                  name: 'rummy game',
                  image: API.CDN_REACT + '/seo/slot_rummy.png',
                },
              ],
            })}
          </script>
        </Helmet>
      </HelmetProvider>
    </>
  );
};

export default StructuredData;
